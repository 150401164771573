import { HomeLogo, LoaderWrapper } from '../../../styles/atoms'
import LoaderIllustration from '../../../assets/logo.png'

function Loading() {

    return (
        <LoaderWrapper>
            <HomeLogo src={LoaderIllustration} />
            <div className="spinner-grow text-warning mt-2" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </LoaderWrapper>
    )
}

export default Loading