import React, {Fragment, useState} from 'react'
import { Button } from "./styles/stylesCheckButtom"

const CheckButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 100){
	setVisible(true)
	}
	else if (scrolled <= 100){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 500,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<Fragment>
		<Button className='rounded-5 shadow bg-success' onClick={scrollToTop}
			style={{display: visible ? 'block' : 'none', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}} data-bs-toggle="modal" data-bs-target="#check">
			Réservez 🕐
		</Button>
	</Fragment>
);
}

export default CheckButton;
