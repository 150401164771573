import { Fragment } from "react"
import { useFetch33, useTheme } from "../../hooks"
import Error from "../public/widgets/error"
import Loading from "../public/widgets/loading"
import { Board, BoardSection, NavLink } from "../../styles/atoms"

function Stats({countBookings}) {

    const { theme } = useTheme()
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null

    const { isLoading33, data31, data32, data33, error33 } = useFetch33(
        `https://api.book-easily.com/api/admin/count_items/ads/category/1`,
        `https://api.book-easily.com/api/admin/count_items/ads/category/2`,
        `https://api.book-easily.com/api/admin/count_items/ads/category/3`,
        token
    ) 
    let countHotels = data31?.data?.visible
    let totalHotels = countHotels + data31?.data?.hidden

    let countMeubles = data32?.data?.visible
    let totalMeubles = countMeubles + data32?.data?.hidden

    let countLocations = data33?.data?.visible
    let totalLocations = countLocations + data33?.data?.hidden

    if (error33) {
        return (
          <Error />
        )
    }

    return (
        <BoardSection>
            { isLoading33 ? (
              <Loading />
              ) : (
                <Fragment>
                    <Board>
                        <div className="row">
                            <div className="col-9 text-start">
                                <NavLink $theme={theme} to='/dashboard/categories/hotels/views_ads'>Hotels</NavLink>
                            </div>
                            <div className="col-3 text-end">
                                <NavLink $theme={theme} to='/dashboard/categories/hotels/new_ads'>+</NavLink>
                            </div>
                        </div>
                        <hr />
                        <p className="text-end">{countHotels} / {totalHotels}</p>
                    </Board >

                    <Board >
                        <div className="row">
                            <div className="col-9 text-start">
                                <NavLink $theme={theme} to='/dashboard/categories/meubles/views_ads'>Meublés</NavLink>
                            </div>
                            <div className="col-3 text-end">
                                <NavLink $theme={theme} to='/dashboard/categories/meubles/new_ads'>+</NavLink>
                            </div>
                        </div>
                        <hr />
                        <p className="text-end">{countMeubles} / {totalMeubles}</p>
                    </Board>

                    <Board>
                        <div className="row">
                            <div className="col-9 text-start">
                                <NavLink $theme={theme} to='/dashboard/categories/non meubles/views_ads'>Non Meublés</NavLink>
                            </div>
                            <div className="col-3 text-end">
                                <NavLink $theme={theme} to='/dashboard/categories/non meubles/new_ads'>+</NavLink>
                            </div>
                        </div>
                        <hr />
                        <p className="text-end">{countLocations} / {totalLocations}</p>
                    </Board>

                    <Board>
                        <NavLink $theme={theme} to='/dashboard'>Réservations</NavLink>
                        <hr />
                        <p className="text-end">{countBookings} </p>
                    </Board>
                </Fragment>
                )}
        </BoardSection>
    )
}

export default Stats