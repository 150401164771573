import { Link } from "react-router-dom"
import { CtaSection } from "../../../styles/atoms"

function CallToAction() {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

    return (
        <CtaSection>
            <h4 className="text-white text-center lead-5">
                Augmentez votre visibilité et vos réservations en publiant vos biens sur <span className="text-warning">BookEasily</span> !
            </h4>
            <div id="carouselCat" className="carousel slide text-center" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <p className="text-white fw-bold d-block w-100">Créez votre compte en moins d'une minute,</p>
                    </div>
                    <div className="carousel-item">
                        <p className="text-white fw-bold d-block w-100">Ajoutez vos biens avec des photos et descriptions attractives,</p>
                    </div>
                    <div className="carousel-item">
                        <p className="text-white fw-bold d-block w-100">Fixez vos prix et et votre calendrier de disponiblité, et</p>
                    </div>
                    <div className="carousel-item">
                        <p className="text-white fw-bold d-block w-100">Commencez à recevoir des reservations !</p>
                    </div>
                </div> 
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselCat" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselCat" data-bs-slide="next">
                    <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                { user ?  
                    user.role_id === 3 ?  
                    <Link to='/dashboard' role="button" className="btn btn-outline-warning btn-md px-4 fw-bold rounded-5">Créez votre annonce !</Link>
                    :
                    <Link to='/partners' role="button" className="btn btn-warning btn-md px-4 fw-bold rounded-5">Créez votre annonce !</Link>
                :  
                    <Link to='/partners' role="button" className="btn btn-warning btn-md px-4 fw-bold rounded-5">Créez votre annonce !</Link>
                }
            </div>
        </CtaSection>
    )
}

export default CallToAction