import { useTheme } from "../../../hooks"
import { BlocBottomNavSection, BottomNavSection, NavLink } from "../../../styles/atoms"

function MobileBottomHeader() {
    const { theme } = useTheme()
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

    return (
        <BottomNavSection>
            <BlocBottomNavSection>
                <div><NavLink to={`/properties/categories/meublés/2`} $theme = {theme}><i className="bi bi-building"></i></NavLink></div>
                <div><p>Meublés</p></div>
            </BlocBottomNavSection>
            <BlocBottomNavSection>
                <div><NavLink to={`/properties/categories/hôtels/1`} $theme = {theme}><i className="bi bi-buildings"></i></NavLink></div>
                <div><p>Hôtels</p></div>
            </BlocBottomNavSection>
            <BlocBottomNavSection>
                <div>
                    { user ?  
                        <NavLink to='/dashboard' $theme = {theme}><i className="bi bi-plus-square"></i></NavLink> 
                        :  
                        <NavLink to='/partners' $theme = {theme}><i className="bi bi-plus-square-fill"></i></NavLink>
                    }
                </div>
                <div><p>Créer</p></div>
            </BlocBottomNavSection>
            <BlocBottomNavSection>
                <div><NavLink to={`/properties/categories/non meublés/3`} $theme = {theme}><i className="bi bi-house-door"></i></NavLink></div>
                <div><p>Locations</p></div>
            </BlocBottomNavSection>
        </BottomNavSection>
    )
}

export default MobileBottomHeader