import { Form, Link } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import img from '../../assets/user.png'
import { LogoProfil, LogoutButton, StyledLink } from '../../styles/atoms'


function Column ({theme}) {
  const [role, setRole] = useState()
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const x_xsrf_token = localStorage.getItem("cookies")
  const id = user.id

  useEffect(() => {user.role_id === 2 && setRole('Client')}, [user])
  useEffect(() => {user.role_id === 3 && setRole('Partenaire')}, [user])

    const logout = async (token) => {
      await fetch(`https://api.book-easily.com/api/logout`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        }, 
      })
      .then((response) => {
        localStorage.clear()
        window.location.assign("/") 
      })
      .catch((err) => {
        console.log(err.message)
      })
     }
   
    const handleLogout = (e) => {
      e.preventDefault() 
      logout(token)
    } 

    return (
      <div className="mt-3">
        <div className="card my__card" style={{ transform: 'scale(1)', }}>
          <div className="d-flex flex-row justify-content-center card-header bg-white" >
            <Link to='/dashboard/user'><LogoProfil src={ user.photo_url ? `${user.photo_url}` : img } alt={ `${user.last_name}` } /></Link>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item text-center"><i className="bi bi-person-lines-fill mx-1"></i><span> {user.last_name} </span><StyledLink to={`/dashboard/user/update/${id}`}><i className="bi bi-pencil-fill"></i></StyledLink></li>
            <li className="list-group-item text-center fw-bold"><i className="bi bi-briefcase-fill mx-1"></i><span> {role} </span> </li>
          </ul>
        </div>
        <div className="card my__card mt-3" style={{ transform: 'scale(1)', }}>
          <div className="card-header bg-warning fw-bold" >
            Paramètres
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <i className="bi bi-person"></i> <StyledLink to='/dashboard/user' >Informations personnelles</StyledLink> 
            </li>
            <li className="list-group-item">
              <i className="bi bi-key"></i> <StyledLink to={`/dashboard/user/update/password/${id}`}>Modifiez votre mot de passe</StyledLink>
            </li>
            <li className="list-group-item">
              <i className="bi bi-credit-card"></i> <StyledLink to='#' >Méthode de paiement</StyledLink> 
            </li>
            <li className="list-group-item">
              <Form method='POST' onSubmit={(e) => handleLogout(e) }>
                <i className="bi bi-box-arrow-up-left"></i> <LogoutButton type='submit'>Deconnexion</LogoutButton>
              </Form>
            </li>
          </ul>
        </div>
        <div className="my__card card mt-3" style={{ transform: 'scale(1)', }}>
          <div className="card-header bg-warning fw-bold">
            Accès rapides
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item"><i className="bi bi-house-check"></i> <StyledLink to='/dashboard'>Tableau de bord</StyledLink> </li>
            { user.role_id === 3 ? 
            <Fragment> <li className="list-group-item">
                <div className="btn-group">
                  <button className="btn p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <i className="bi bi-bag-plus"></i> <StyledLink>Ajoutez une annonce</StyledLink>
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item"><StyledLink  to='/dashboard/categories/hotels/new_ads' ><i className="bi bi-arrow-right-circle"></i> Hotel</StyledLink></li>
                    <li className="dropdown-item"><StyledLink  to='/dashboard/categories/meubles/new_ads' ><i className="bi bi-arrow-right-circle"></i> Meublé</StyledLink></li>
                    <li className="dropdown-item"><StyledLink  to='/dashboard/categories/non_meubles/new_ads' ><i className="bi bi-arrow-right-circle"></i> Location/Non Meublé</StyledLink></li>
                  </ul>
                </div>
              </li> 
              <li className="list-group-item">
                <button className="btn p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                  <i className="bi bi-eye"></i> <StyledLink >Voir vos annonces</StyledLink>
                </button>
                <ul className="dropdown-menu">
                  <li className="dropdown-item"><StyledLink  to='/dashboard/categories/hotels/views_ads' ><i className="bi bi-arrow-right-circle"></i> Hotels</StyledLink></li>
                  <li className="dropdown-item"><StyledLink  to='/dashboard/categories/meubles/views_ads' ><i className="bi bi-arrow-right-circle"></i> Meublés</StyledLink></li>
                  <li className="dropdown-item"><StyledLink  to='/dashboard/categories/non_meubles/views_ads' ><i className="bi bi-arrow-right-circle"></i> Locations/Non Meublés</StyledLink></li>
                </ul>
              </li>
            </Fragment>
            : null }
          </ul>
        </div>
      </div>
    )
}

export default Column