import { Fragment, useEffect, useState } from "react"
import { Link, useParams  } from "react-router-dom"
import logo from '../../../assets/logo.png'
import PhoneInput from 'react-phone-number-input'
import { GridBoardForm, HomeLogo } from "../../../styles/atoms"
import HelpButton from "../../../composants/public/widgets/helpButton"

function BookingUpdate () {
  document.title = `BookEasily | Modifiez vos informations de reservation`

  const { param_id } = useParams()
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const x_xsrf_token = localStorage.getItem("cookies")

  const [last_name, SetLastName] = useState('')
  const [else_full_name, setElseFullName] = useState('')
  const [else_phone, setElsePhone] = useState('')
  const [for_me, setForMe] = useState(false)
  const [first_name, SetFirstName] = useState('')
  const [value, setValue] = useState('')
  const [else_email, setElseEmail] = useState('')
  const [email, setEmail] = useState('')
  const [entering_at, setEnteringAt] = useState('')
  const [living_at, setLivingAt] = useState('')
  const [number_persons, setNumberPersons] = useState('')
  const [n_pieces, setNPieces] = useState('')
  const [status, setStatus] = useState('')
  const [booking, setBooking] = useState('')
  const [load, setLoad] = useState(0)

  useEffect(() => {
    const fetchPost = async (param_id, token, x_xsrf_token) => {
      const response = await fetch(
        `https://api.book-easily.com/api/booking/${param_id}`, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
              'Authorization': `Bearer ${token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Accept": "application/json",
              'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        }
      )
      const data = await response.json()
      if (data?.status === 1) {
        setBooking(data?.data)
        setEmail(data?.data.email)
        setEnteringAt(data?.data.entering_at)
        SetLastName(data?.data.last_name)
        SetFirstName(data?.data.first_name)
        setLivingAt(data?.data.living_at)
        setNumberPersons(data?.data.number_persons)
        setValue(data?.data.phone)
        setForMe(data?.data.for_me)
        setElseFullName(data?.data.else_full_name)
        setElsePhone(data?.data.else_phone)
        setElseEmail(data?.data.else_email)
        setNPieces(data?.data.n_pieces)
      }
    };
    fetchPost(param_id, x_xsrf_token)
  }, [param_id, token, x_xsrf_token])

  const forms = new FormData()
  last_name !== booking.last_name && forms.append("last_name", last_name)
  first_name !== booking.first_name && forms.append("first_name", first_name)
  value !== booking.phone && forms.append("phone", value)
  email !== booking.email && forms.append("email", email)
  number_persons !== booking.number_persons && forms.append("number_persons", number_persons)
  entering_at !== booking.entering_at && forms.append("entering_at", entering_at)
  living_at !== booking.living_at && forms.append("living_at", living_at)
  n_pieces !== booking.n_pieces && forms.append("n_pieces", n_pieces)
  forms.append("ad_id", param_id)
  if (for_me === true || for_me === 1) {
    forms.append('for_me', 1)
  } 
  if (for_me === false || for_me === 0)  {
      forms.append('for_me', 0)
      else_full_name !== booking.else_full_name && forms.append('else_full_name', else_full_name)
      else_email !== booking.else_email && forms.append("else_email", else_email)
      else_phone !== booking.else_phone && forms.append("else_phone", else_phone)
  }

  async function handleBooking(e, forms, token) {
    e.preventDefault()
    setLoad(1)

    await fetch(`https://api.book-easily.com/api/booking/${param_id}`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        body: forms,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept' : 'application/json; charset=UTF-8',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        },
    })
    .then((response) => response.json())
    .then((data) => {
      setLoad(0)
      setStatus(data?.status)
      if (data?.status === 1) {
        window.location.assign('/dashboard')
      }
    })
    .catch((err) => {
      setLoad(0)
      console.log(err.message)
    })
  } 

  return (
    <Fragment>
      <div className='container justify-content-center col-md-10 offset-md-1 col-12'>
        <form className="my__form g-2 p-3 my-5 mx-3" onSubmit={(e) => handleBooking(e, forms, token)}>
          <div className="col-md-12 text-center">
            <Link to="/dashboard">
              <HomeLogo src={logo} alt="logo bookEasily" />
            </Link>
          </div>
          { status === 1 && 
              (<div className="alert alert-info" role="alert">
                  Votre reservation a été modifiée avec succès ! Consultez vos mails pour voir votre facture. 
              </div> )
          }
          { status === 0 && 
              (<div className="alert alert-danger" role="alert">
                  Vérifiez à nouveau vos informations. 
              </div>)
          }
          <div className="col-12 my-1">
            <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to= { `/dashboard` } className='text-warning'> Accueil</Link>
          </div>         
          <div className="form-check col-12 mt-4 mb-2">
              <input className="form-check-input" type="checkbox" checked={for_me} id="for_me" name='for_me' onChange={(e) => setForMe(e.target.checked) }/>
              <label className="form-check-label" htmlFor="for_me">
                  Je reserve pour moi
              </label>
          </div>
          <GridBoardForm>
            <div className="col-12">
              <label htmlFor="lastName" className="form-label">Nom</label>
              <input type="text" className="form-control rounded-5" id="lastName" name="lastName" value={last_name} onChange={(e) => SetLastName(e.target.value) }/>
            </div>

            <div className="col-12">
              <label htmlFor="firstName" className="form-label">Prénom</label>
              <input type="text" className="form-control rounded-5" id="firstName" name="firstName" value={first_name} onChange={(e) => SetFirstName(e.target.value) }/>
            </div>

            <div className="col-12">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control rounded-5" id="email" name="email" value={email} placeholder="nom@example.com" onChange={(e) => setEmail(e.target.value) }/>
            </div>

            <div className="col-12">
              <label htmlFor="phone" className="form-label">Téléphone</label>
              <PhoneInput id="phone" name="phone" className="form-control rounded-5"
                placeholder="Indicatif de votre pays + numéro..."
                value={value}
                onChange={setValue}/>
            </div>
            <div className="col-12">
                <label htmlFor="start_date" className="form-label">Date d'arrivée</label>
                <div className="input-group">
                    <div className="input-group-text">Du</div>
                    <input type="datetime-local" className="form-control" id="start_date" max={living_at} value={entering_at} name="start_date" onChange={(e) => setEnteringAt(e.target.value)}/>
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="end_date" className="form-label">Date de départ</label>
                <div className="input-group">
                    <div className="input-group-text">Au</div>
                    <input type="datetime-local" className="form-control" id="end_date" min={entering_at} value={living_at} name="end_date" onChange={(e) => setLivingAt(e.target.value) }/>
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="number_persons" className="form-label">Nombre de personnes</label>
                <div className="input-group"> 
                    <input type="number" className="form-control rounded-5" placeholder="Nombre de personnes" value={number_persons} id="number_persons" name="number_persons"  onChange={(e) => setNumberPersons(e.target.value) } />
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="number_pieces" className="form-label">Nombre de pièces</label>
                <div className="input-group">
                    <input type="number" className="form-control rounded-5" placeholder="Nombre de pièces" value={n_pieces} id="number_pieces" name="number_pieces" onChange={(e) => setNPieces(e.target.value)} />
                </div>
            </div>
        </GridBoardForm>


          { for_me === false ?
          <Fragment>
            <hr className="my-3 text-warning" />
            <div className="col-12 my-2">
              <label htmlFor="else_full_name" className="form-label">Nom complet de la personne *</label>
              <input type="text" className="form-control rounded-5" id="else_full_name" name="else_full_name" value={else_full_name} onChange={(e) => setElseFullName(e.target.value) }/>
            </div>

            <GridBoardForm>
              <div className="col-12">
                <label htmlFor="else_phone" className="form-label">Numéro de téléphone de la personne *</label>
                <PhoneInput id="else_phone" name="else_phone" className="form-control rounded-5"
                  placeholder="Indicatif de votre pays + numéro..."
                  value={else_phone}
                  onChange={setElsePhone}/>
              </div>

              <div className="col-12">
                <label htmlFor="else_email" className="form-label">Email de la personne *</label>
                <input type="email" className="form-control rounded-5" id="else_email" name="else_email" value={else_email} placeholder="nom@example.com" onChange={(e) => setElseEmail(e.target.value) }/>
              </div>
            </GridBoardForm>
          </Fragment>
          : null }
          <div className="col-12 my-3 text-warning">
            * = champ obligatoire
          </div>
          { load === 0 ? 
                  <button className="w-100 btn btn-outline-warning fw-bold rounded-5" type="submit">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Modifiez vos informations</span>  }</button>
              :
                  <button className="btn btn-outline-warning fw-bold w-100 rounded-5" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                  </button>
          }
        </form>
      </div>
      <HelpButton />
    </Fragment>
  )
}

export default BookingUpdate