import T1 from '../assets/villalou/T1.jpg'
import T2 from '../assets/villalou/T2.jpg'
import T3 from '../assets/villalou/T3.jpg'
import T4 from '../assets/villalou/T4.jpg'

import E1 from '../assets/villalou/E1.jpg'
import E2 from '../assets/villalou/E2.jpg'
import E4 from '../assets/villalou/E4.jpg'
import E5 from '../assets/villalou/E5.jpg'
import E6 from '../assets/villalou/E6.jpg'
import E7 from '../assets/villalou/E7.jpg'
import E8 from '../assets/villalou/E8.jpg'
import E9 from '../assets/villalou/E9.jpg'

import M1 from '../assets/maison_rose/1.jpg'
import M2 from '../assets/maison_rose/2.jpg'
import M3 from '../assets/maison_rose/3.jpg'
import M4 from '../assets/maison_rose/4.jpg'


import N1 from '../assets/immeuble_ngue/N1.jpg'
import N2 from '../assets/immeuble_ngue/N2.jpg'
import N3 from '../assets/immeuble_ngue/N3.jpg'

export const pubList = [
    {
        id: '1',
		cover: [T1, T2, T3, T4],
		town: 'Kribi',
        title: 'Terrains titrés et lotis',
        price: '15.000 XAF/M²',
        description: 'Terrain à grand batanga titré et lotis de 3.000 M².'
	},
    {
        id: '2',
		cover: [E1, E2, E4, E5, E6, E7, E8, E9],
		town: 'Kribi',
        title: 'Espace évenementiel en bordure de mer',
        price: '250.000 XAF/24H | 300.000 XAF/48H',
        description: "Espace évenementiel en bordure de mer, plage grand batanga Musée des Arts. Idéal pour organiser vos évènements tels que mariage, anniversaire, bapteme, etc..."
	},
    {
        id: '3',
		cover: [M1, M2, M3, M4],
		town: 'Douala',
        title: 'Espace évènementiel situé à la terrasse de la maison rose à bonamoussadi.',
        price: '60.000 XAF/Jour',
        description: "Salle de 60 places assises + WIFI + 02 Toilettes H et F + Cambuse + Cabine DJ + Tables et chaises de réception"
	},
    {
        id: '4',
        cover: [N1, N2, N3],
        town: 'Yaoundé',
        title: 'Immeuble Ngué Ayéné',
        price: '150 Millions',
        description: "Immeuble Ngué Ayéné (Ekounou III, Université Catholique) Situé à 250 mètres de l’entrée du campus de l’UCAC R+3 avec sous-sol 723 m2 Sol entièrement recouvert de marbre. 6 grands appartements (2 à chaque étage) comprenant chacun 3 chambres dont l’une avec salle de bain attenante et balcon, 1e cuisine et son balcon, 1e salle de séjour spacieuse et son balcon, 1e salle de bain) et 2 appartements plus petits au rez-de-chaussée. Parking + 6 garages. 6 grands magasins (servant actuellement de chambres) au sous-sol. L’immeuble possède son propre transformateur. Y a 1 contrat en cours de validité avec IHS concernant l’antenne relais sur le toit de l’immeuble. Valeur locative actuelle: 15M/an",
    }
]