import styled from 'styled-components'

export const Button = styled.div`
    position: fixed;
    width: auto;
    left: 3.5%;
    bottom: 15px;
    height: auto;
    font-size: 0.8rem;
    z-index: 1;
    cursor: pointer;
    padding: 0.6rem;
    font-weight: 600;
    color: white;

    @media (max-width: 540px) {
        left: 3%;
        bottom: 80px;
    }
`
