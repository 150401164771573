import { useTheme } from "../../../hooks"
import { GridHelp, StyledTitle, StyledTitle3 } from "../../../styles/atoms"

function Faqs () {
    const { theme } = useTheme()

    return(
        <div>
            <StyledTitle theme={theme} >Questions courantes (FAQs)</StyledTitle>
            <GridHelp>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >A quoi sert la commission que nous vous donnons ?</StyledTitle3>
                        <p className="text-muted">Pour lancer les campagnes marketing pour vous mettre en avant en ligne, 

                            proposer des rabais (à nos frais) au client pour les encourager à effectuer une réservation, 

                            Assistante disponible 7j/7
                        </p>
                    </div>
                </div>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >Dois je confirme une réservation ?</StyledTitle3>
                        <p className=" text-muted">Malheureusement non, vous n'avez pas la possibilité de confirmer une réservation.</p>
                    </div>
                </div>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >Pourrais annuler la réservation d'un client ?</StyledTitle3>
                        <p className=" text-muted">Oui, et vous devez par la suite procéder immédiatement au remboursement total y compris les frais de transaction bancaire si vous avez déjà reçu le virement.</p>
                    </div>
                </div>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >Que se passe t'il si le client ne vient pas ?</StyledTitle3>
                        <p className=" text-muted">Si la réservation de votre logement est gratuite alors vous ne payez aucune commission
                            Par contre si elle est payante, vous allez nous verser la commission sur le montant que le client à payer pour effectuer la réservation.</p>
                    </div>
                </div>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >Pour une nuitée, pourrais je choisir mon heure d'arrivée et mon heure de départ ?</StyledTitle3>
                        <p className=" text-muted">Malheureusement non, vous ne pouvez choisir que la date de départ, la date d'arrivée et préciser le nombre de personnes et de logement que vous voulez.</p>
                    </div>
                </div>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >Comment réserver pour une nuitée ?</StyledTitle3>
                        <p className=" text-muted">Pour pouvoir réserver il faut choisir le logement qui vous intéresse et vérifier la disponibilité en entrant l'heure le jour d'arrivée, le jour de départ, le nombre de personnes que vous êtes êtes et le nombre de logements.</p>
                    </div>
                </div>
                <div className="col d-flex py-2 align-items-start">
                    <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                        <i className="bi bi-quote text-warning"></i>
                    </div>
                    <div>
                        <StyledTitle3 theme={theme} >Comment être sûr que ma réservation à abouti ?</StyledTitle3>
                        <p className=" text-muted">Lorsque vous finaliser votre réservation, nous vous envoyons le message de confirmation par mail que vous pourriez également présenté à l'accueil de l'hébergement.</p>
                    </div>
                </div>
            </GridHelp>
        </div>
    )
}


export default Faqs