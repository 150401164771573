import styled from 'styled-components'

export const Button = styled.a`
    position: fixed;
    width: auto;
    left: 75%;
    bottom: 80px;
    height: auto;
    font-size: 0.8rem;
    z-index: 1;
    cursor: pointer;
    color: white;
    padding: 10px;
`
