const colors = {
    primary: '#DF9600',
    secondary: '#f1f1f1',
    backgroundLight: '#fff',
    backgroundDark: '#111',
    dark: '#111',
    light: '#fff',
    darkSection : '#2C4265',
  }
  
  export default colors