import { Fragment } from 'react'
import ErrorIllustration from '../../../assets/error.png'
import { Link } from 'react-router-dom'
import { useTheme } from '../../../hooks'
import Header from '../sections/header'
import Footer from '../sections/footer'
import MobileBottomHeader from '../sections/mobileBottomHeader'
import { ErrorTitle, ErrorWrapper, Illustration, MainContainer } from '../../../styles/atoms'
import ScrollButton from './scrollUp'

function Error() {
  const { theme } = useTheme()

  const handleReload = (e) => {
    e.preventDefault() 
    window.location.reload()
  }

  return (
    <Fragment>
      <Header />
      <MainContainer theme={theme}>
        <ErrorWrapper theme={theme}>
          <ErrorTitle theme={theme}>Oups, la page est actuellement indisponible pour une maintenance technique.</ErrorTitle>
          <Illustration src={ErrorIllustration} />
          <Link role="button" className="btn btn-warning btn-md px-4 m-3 fw-bold" onClick={(e) => handleReload(e)}>Rafraichir la page</Link> 
        </ErrorWrapper>
        <Footer />
      </MainContainer>
      <ScrollButton />
      <MobileBottomHeader/>
    </Fragment>
  )
}

export default Error