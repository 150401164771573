import { BadgeSection } from '../../../styles/atoms'

function Badge() {

    return (
        <BadgeSection>
            <div id="carouselBadge" className="carousel slide text-center" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <h4 className="text-white fw-bold d-block w-100">Services clients 7j/7</h4>
                    </div>
                    <div className="carousel-item">
                        <h4 className="text-white fw-bold d-block w-100">Paiements sécurisés</h4>
                    </div>
                </div> 
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselBadge" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselBadge" data-bs-slide="next">
                    <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </BadgeSection>
    )
}

export default Badge