import { useState, useEffect, useContext } from 'react'
import { ThemeContext } from '../context'

const x_xsrf_token = localStorage.getItem("cookies")

export function useTheme() {
  const { theme, toggleTheme } = useContext(ThemeContext)
  return { theme, toggleTheme }
}

export function useFetch(url, token) {
  const [data11, setData11] = useState({})
  const [isLoading11, setLoading11] = useState(true)
  const [error11, setError11] = useState(false)

  useEffect(() => {
      if (!url) 
      return
      async function getData(url, token) {
        try {
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept': 'application/json',
              'X-XSRF-TOKEN': `${x_xsrf_token}`
            }
          })
          const data = await response.json()
          setData11(data)           
        } catch (err) {
          setError11(true)
        } finally {
          setLoading11(false)
        }
      } getData(url, token)
    }
  , [url, token])
  return { isLoading11, data11, error11 }
}


export function useFetch22(urlA, urlB, token) {
  const [data21, setData21] = useState({})
  const [data22, setData22] = useState({})
  const [isLoading22, setLoading22] = useState(true)
  const [error22, setError22] = useState(false)

  useEffect(() => {
      if (!urlA || !urlA ) 
      return
      async function getData(urlA, urlB) {
        try {
          const responseA = await fetch(urlA, {
            method: "GET",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
              'X-XSRF-TOKEN': `${x_xsrf_token}`
            }
          })
          const dataA = await responseA.json()
          setData21(dataA)
  
          const responseB = await fetch(urlB, {
            method: "GET",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
              'X-XSRF-TOKEN': `${x_xsrf_token}`
            }
          })
          const dataB = await responseB.json()        
          setData22(dataB) 
        } catch (err) {
          setError22(true)
        } finally {
          setLoading22(false)
        }
      } getData(urlA, urlB, token)
    }
  , [urlA, urlB, token])
  return { isLoading22, data21, data22, error22 }
}

export function useFetch33(urlA, urlB, urlC, token) {
  const [data31, setData31] = useState({})
  const [data32, setData32] = useState({})
  const [data33, setData33] = useState({})
  const [isLoading33, setLoading33] = useState(true)
  const [error33, setError33] = useState(false)

  useEffect(() => {
    if (!urlA || !urlB || !urlC) 
    return
    async function getData(urlA, urlB, urlC) {
      try {
        
        //categories
        const responseA = await fetch(urlA, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataA = await responseA.json()        
        setData31(dataA) 

        //types
        const responseB = await fetch(urlB, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataB = await responseB.json()        
        setData32(dataB) 

        //streets
        const responseC = await fetch(urlC, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataC = await responseC.json()        
        setData33(dataC)

      } catch (err) {
        setError33(true)
      } finally {
        setLoading33(false)
      }
    }
    getData(urlA, urlB, urlC);
  }, [urlA, urlB, urlC, token])
  return { isLoading33, data31, data32, data33, error33 }
}

export function useFetch44(urlA, urlB, urlC, urlD, token) {
  const [data41, setData41] = useState({})
  const [data42, setData42] = useState({})
  const [data43, setData43] = useState({})
  const [data44, setData44] = useState({})
  const [isLoading44, setLoading44] = useState(true)
  const [error44, setError44] = useState(false)

  useEffect(() => {
    if (!urlA || !urlB || !urlC || !urlC) 
    return
    async function getData(urlA, urlB, urlC, urlD) {
      try {
        const responseA = await fetch(urlA, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataA = await responseA.json()        
        setData41(dataA) 

        //types
        const responseB = await fetch(urlB, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataB = await responseB.json()        
        setData42(dataB) 

        //streets
        const responseC = await fetch(urlC, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataC = await responseC.json()        
        setData43(dataC)

        const responseD = await fetch(urlD, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        })
        const dataD = await responseD.json()        
        setData44(dataD)

      } catch (err) {
        setError44(true)
      } finally {
        setLoading44(false)
      }
    }
    getData(urlA, urlB, urlC, urlD);
  }, [urlA, urlB, urlC, urlD, token])
  return { isLoading44, data41, data42, data43, data44, error44 }
}
