import { Fragment, useState } from "react"
import { useTheme } from "../../hooks"
import { DeleteButton, PaginateLink, StyledLink, StyledTitle2, TD } from '../../styles/atoms'

function AvailableAds({group, datas}) {
    const { theme } = useTheme()
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const x_xsrf_token = localStorage.getItem("cookies")
    const [books, setBooks] = useState(datas?.data)
    const [metaLinks, setMetaLinks] = useState( datas?.data !== null && datas?.meta?.links)
    const [ cancellation_reason, setCancellationReason ] = useState('')
    const [ param_id, setParamId ] = useState('')

    const forms = new FormData()
    forms.append("cancellation_reason", cancellation_reason)

    async function handleDeleteBooking (e, param_id){
        e.preventDefault()

        await fetch(`https://api.book-easily.com/api/cancel/booking/${param_id}`, {
        method: 'POST',
        mode: "cors",
        cache: "default",
        credentials: "include",
        body: forms,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json; charset=UTF-8',
            'X-XSRF-TOKEN': `${x_xsrf_token}`
        },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.status === 1) {
                window.location.reload()
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    async function handlePaginate (e, url){
        e.preventDefault()

        await fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "default",
            credentials: "include",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.status === 1) {
                setBooks(data?.data)
                setMetaLinks(data?.meta?.links)
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    return (
    <Fragment>
        <div className="table-responsive my__card card mt-3">
            <table className="table table-sm">
            <caption className="m-3"><StyledTitle2 theme={theme}>{group}</StyledTitle2></caption>
            <thead className="bg-warning text-capitalize">
                <tr>
                <th scope="col" className="p-2">Statut</th>
                <th scope="col" className="p-2">Annonce</th>
                <th scope="col" className="p-2">Modifier</th>
                <th scope="col" className="p-2">Annuler</th>
                <th scope="col" className="p-2">Fait le</th>
                <th scope="col" className="p-2">Debut</th>
                <th scope="col" className="p-2">fin</th>
                <th scope="col" className="p-2">Nombre de pièces</th>
                </tr>
            </thead>
            <tbody> 
            {books ? books.map(({id, canceled, n_pieces, ad_id, entering_at, living_at, transaction_date, ref, out_book}) =>  out_book === 1 &&
                <tr key={ref}>
                    <TD theme={theme}>{ canceled === 1 ? <span>Disponible</span> : <span>Indisponible</span> }</TD>
                    <TD theme={theme}><StyledLink target="_blank" $theme={theme} to={`/check/property/${ad_id}`}> <i className="bi bi-eye-fill"></i></StyledLink></TD>
                    
                    <TD theme={theme}>
                        <StyledLink $theme={theme} to={`/dashboard/availability/update/${id}`}>
                            <i className="bi bi-pencil-fill"></i>
                        </StyledLink>
                    </TD>
                    <TD theme={theme}>
                        { canceled === 1 ? 
                            <DeleteButton className="disabled"><i className="bi bi-x-circle-fill"></i></DeleteButton> 
                        : 
                            <DeleteButton onClick={(e) => {setParamId(id)}} data-bs-toggle="modal" data-bs-target="#deleteBookedBooking"><i className="bi bi-x-circle-fill"></i></DeleteButton>
                        }
                    </TD>
                    <TD theme={theme}>
                        {(() => {
                        let date = new Date(transaction_date)
                        date = date.toLocaleString()
                        return date;
                        })()}
                    </TD>
                    <TD theme={theme}>
                        {(() => {
                        let date = new Date(entering_at)
                        date = date.toLocaleString()
                        return date;
                        })()}
                    </TD>
                    <TD theme={theme}>
                        {(() => {
                        let date = new Date(living_at)
                        date = date.toLocaleString()
                        return date;
                        })()}
                    </TD>
                    <TD theme={theme}>{n_pieces} </TD>
                </tr>
                ) : null}
            </tbody>
            </table>
        </div>
        <nav aria-label="..." className="mt-3">
            <ul className="pagination justify-content-center"> 
            { metaLinks && metaLinks.map(({url, label, active}) => active ?
                (<li className="page-item" key={label}>
                { url ? 
                    <PaginateLink className='page-link' style={{ backgroundColor: '#DF9600', color: 'white' }} onClick={(e) => handlePaginate(e, url)}>{label}</PaginateLink>
                : 
                    null
                }
                </li>) :
                
                (<li className="page-item" key={label}>
                { url ? 
                    <PaginateLink className='page-link' onClick={(e) => handlePaginate(e, url)}>{label}</PaginateLink>
                : 
                    null
                }
                </li> )
            )}
            </ul>
        </nav>

        <div className="modal fade" id="deleteBookedBooking" tabIndex="-1" aria-labelledby="deleteBookedBookingModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="deleteBookedBookingModal">Annulation de l'indisponibilité de l'annonce N°{param_id}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => handleDeleteBooking(e, param_id) }>
                            <input type="text" name="cancellation_reason" className="form-control my-2" placeholder="Raison de votre annulation" id="cancellation_reason" onChange={(e) => {setCancellationReason(e.target.value)}} required />
                            <DeleteButton type="submit"><i className="bi bi-x-circle-fill"></i> Annuler</DeleteButton>
                        </form>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export default AvailableAds