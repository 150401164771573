import { Fragment } from 'react'
import { useFetch22, useTheme } from '../../../hooks'
import Error from '../../../composants/public/widgets/error'
import Loading from '../../../composants/public/widgets/loading'
import Header from '../../../composants/public/sections/header'
import { ContactSection, HelpSection, MainContainer, StyledTitle2 } from '../../../styles/atoms'
import Footer from '../../../composants/public/sections/footer'
import CallToAction from '../../../composants/public/sections/callToAction'
import Faqs from '../../../composants/public/sections/faqs'
import Towns from '../../../composants/public/sections/towns'
import Explore from '../../../composants/public/sections/explore'
import Hero from '../../../composants/public/sections/hero'
import CardCategories from '../../../composants/public/cards/cardCategories'
import ScrollButton from '../../../composants/public/widgets/scrollUp'
import MobileBottomHeader from '../../../composants/public/sections/mobileBottomHeader'

function About (){
    document.title = `BookEasily - Comment ça marche ?`
    const { theme } = useTheme()
    // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null

    const { isLoading22, data21, data22, error22 } = useFetch22(
      `https://api.book-easily.com/api/towns`,
      `https://api.book-easily.com/api/categories`,
      token
    )

    let villes = data21?.data
    let categories = data22?.data

    if (error22) {
      return (
        <Error />
      )
      
    }

    return (
      <Fragment>
          {/* Header Section */}
          <Header/>

          <MainContainer theme={theme}>
            {/* Hero Section */}
            <Hero/>

            {isLoading22 ? (
            <Loading/>
            ) : (
            <Fragment>
              {/* { user.email_verified === 0 && 
              (<div className="alert alert-danger text-center" role="alert">
                  Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
              </div>)
          } */}
            {/* FAQs Section */}
              <HelpSection>
                <div className="col-12 col-md-6 col-xl-4 py-2 order-md-last">
                  <form className="my__form g-2 p-3">
                    <StyledTitle2 theme={theme} >Besoin d'aide ?</StyledTitle2>
                    <div className="col-sm-12 mb-2">
                      <label htmlFor="name" className="form-label">Nom</label>
                      <input type="text" className="form-control rounded-5" id="name" name="name" placeholder="votre nom" />
                    </div>
                    <div className="col-sm-12 mb-2">
                      <label htmlFor="email" className="form-label">Email *</label>
                      <input type="email" className="form-control rounded-5" id="email" name="email" placeholder="nom@example.com" required/>
                    </div>
                    <div className="mb-2">
                      <label htmlFor="message" className="form-label">Message *</label>
                      <textarea className="form-control" id="message" name="message" rows="5" required></textarea>
                    </div>
                    <div className="col-12" id='contact'>
                      <button type="submit" className="btn w-100 btn-outline-warning fw-bold me-2 rounded-5"><i className="bi bi-send-check text-warning"></i> Envoyer</button>
                    </div>
                  </form>
                </div>
                <div className="col-12 col-md-6 col-xl-8 order-md-first">
                  <div className="row row-cols-1 row-cols-xl-2 align-items-stretch g-1 m-1">
                      {categories.map(({id, name, image, description, n_ads}) => 
                          <CardCategories key={id} id={id} name={name} image={image} description={description} count={n_ads} /> 
                        ) 
                      }
                  </div>
                </div>
              </HelpSection>

              {/* CTA Section */}
              <CallToAction />
    
              {/* Contact Section */}
              <ContactSection theme={theme}>
                <Faqs />
              </ContactSection>

              {/* Towns Section */}
              <Towns villes={villes} />
    
              <Explore />
            </Fragment>
          )}
          </MainContainer>

        {/* Footer Section */}
        <Footer />
        <ScrollButton />
        <MobileBottomHeader/>
      </Fragment>
    )
}

export default About
