import { Fragment, useState } from 'react'
import { Link  } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useTheme } from '../../hooks'
import { GridBoardForm, HomeLogo, LogSection, StyledLink } from '../../styles/atoms'
import logo from '../../assets/logo.png'
import HelpButton from '../../composants/public/widgets/helpButton'

function Register () {
    document.title = `BookEasily | Rejoignez notre communauté`

    const { theme } = useTheme()
    const [role, setRole] = useState('')
    const [last_name, SetLastName] = useState('')
    const [first_name, SetFirstName] = useState('')
    const [value, setValue] = useState()
    const [country, setCountry] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [photo, setPhoto] = useState()
    const [password, setPassword] = useState('')
    const [password_confirmation, setConfirmPassword] = useState('')
    const [status, setStatus] = useState('')
    const [statusPassword, setStatusPassword] = useState('')
    const [load, setLoad] = useState(0)

    const forms = new FormData()
    forms.append('role', role)
    forms.append('last_name', last_name)
    forms.append('first_name', first_name)
    forms.append('country', country)
    forms.append('phone', value)
    forms.append('email', email)
    forms.append('address', address)
    if (password === password_confirmation) { 
        forms.append('password', password)
        forms.append('password_confirmation', password_confirmation)
    }
    forms.append('phoneCountry', '+237')

    if (photo) {
        forms.append('photo', photo[0])
    } 

    async function handleRegister(e, forms) {
        e.preventDefault() 
        setLoad(1)

        if (password === password_confirmation) {
            await fetch(`https://api.book-easily.com/api/register`, {
                method: "POST",
                mode: "cors",
                cache: "default",
                credentials: "include",
                redirect: 'manual',
                headers: {
                    'Accept': 'application/json; charset=UTF-8',
                },
                body: forms,
            })
            .then((response) => response.json())
            .then((data) => {
                setStatus(data?.status)
                setLoad(0)
                if (data?.status === 1) {
                    const xsrf_token = document.cookie
                    localStorage.setItem("cookies", xsrf_token.split('=')[1])
                    localStorage.setItem("token", JSON.stringify(data?.data.token))
                    localStorage.setItem("user", JSON.stringify(data?.data.user))
                    window.location.assign("/email/verification-notification")
                }
            })
            .catch((err) => {
                setLoad(0)
                console.log(err.message)
            })
        } else {
            setStatusPassword(0)
        }
    } 

    const upload = (e) => {
        const fileInput = document.getElementById('photo');
        const files = fileInput.files;
        setPhoto(files)
    }

    return (
        <Fragment>
            <LogSection>
                <form className="my__form g-2 p-3 my-5 col-md-8" method="POST" onSubmit={(e) => handleRegister(e, forms)}>
                    <div className="col-md-12 text-center">
                        <Link to="/">
                            <HomeLogo src={logo} alt="logo BookEasily" />
                        </Link>
                    </div>
                    { status === 1 && 
                        (<div className="alert alert-info  text-center" role="alert">
                            Bienvenue sur BookEasily, {JSON.parse(localStorage.getItem("user")).first_name} ! 
                        </div> )
                    }
                    { statusPassword === 0 && 
                        (<div className="alert alert-danger text-center" role="alert">
                            Vérifiez à nouveau votre mot de passe. 
                        </div>)
                    }
                    { status === 0 && 
                        (<div className="alert alert-danger  text-center" role="alert">
                            Vérifiez à nouveau vos informations. 
                        </div>)
                    }
                    <div className="col-12 my-1">
                        <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/" className='text-warning'> Accueil</Link>
                    </div>
                    <GridBoardForm>
                        <div className="my-1">
                            <input type="text" className="form-control rounded-5" id="last_name" name="last_name" placeholder="Entrez votre nom..." value={last_name} onChange={(e) => SetLastName(e.target.value) } required/>
                            <small className="text-muted">Nom *</small>
                        </div>
                        <div className="my-1">
                            <input type="text" className="form-control rounded-5" id="first_name" name="first_name" placeholder="Entrez votre prénom..." value={first_name} onChange={(e) => SetFirstName(e.target.value) } required/>
                            <small className="text-muted">Prénom *</small>
                        </div>
                    </GridBoardForm>
                    <GridBoardForm>
                        <div className="my-1">
                            <input type="email" className="form-control rounded-5" id="email" name="email" placeholder="Entrez votre email..." value={email} onChange={(e) => setEmail(e.target.value) } required/>
                            <small className="text-muted">Email *</small>
                        </div>
                        <div className="my-1">
                            <select className="form-select rounded-5" id="role" name="role" onChange={(e) => setRole(e.target.value) } required>
                                <option value="">Type de compte</option>
                                <option value='client' >Client</option>
                                <option value='admin'>Partenaire</option>
                            </select>
                            <small className="text-muted">Type de compte *</small>
                        </div>
                    </GridBoardForm>
                    <div className="col-12 my-2">
                        <input type="file" accept="image/png, image/jpeg, image/jpg, image/webp, image/tiff, image/svg" className="form-control rounded-5" id="photo" name="photo" placeholder="Entrez une photo de profil..." onChange={upload} />
                        <small className="text-warning">Photo de profil</small>
                    </div>
                    <GridBoardForm>
                        <div className="my-1">
                            <input type="text" className="form-control rounded-5" id="country" name="country" value={country} placeholder="Entrez le nom de votre pays de résidence..." onChange={(e) => setCountry(e.target.value) } required/>
                            <small className="text-muted">Pays *</small>
                        </div>
                        <div className="my-1">
                            <input type="text" className="form-control rounded-5" id="address" name="address" value={address} placeholder="Entrez votre adresse de résidence..." onChange={(e) => setAddress(e.target.value) } required/>
                            <small className="text-muted">Adresse *</small>
                        </div>
                    </GridBoardForm>
                    <div className="col-12 my-2">
                        <PhoneInput id="phone" name="phone" className="form-control rounded-5"
                            placeholder="Sélectionner l'indicatif de votre pays et saisir votre numéro..."
                            value={value}
                            defaultCountry='CM'
                            onChange={setValue} required/>
                        <small className="text-muted">Numéro de téléphone *</small>
                    </div>
                    <GridBoardForm>
                        <div className="my-1">
                            <input type="password" className="form-control rounded-5" id="password" minLength='8' name="password" value={password} placeholder="Entrez votre mot de passe..." onChange={(e) => setPassword(e.target.value) } required/>
                            <small className="text-muted">Mot de passe * / 8 caractères minimum</small>
                        </div>
                        <div className="my-1">
                            <input type="password" className="form-control rounded-5" id="password_confirmation" minLength='8' name="password_confirmation" value={password_confirmation} placeholder="Confirmez votre mot de passe..." onChange={(e) => setConfirmPassword(e.target.value) } required/>
                            <small className="text-muted">Confirmation de mot de passe *</small>
                        </div>
                    </GridBoardForm>
                    <div className="col-12 my-1 text-warning">
                        * = champ obligatoire
                    </div>
                    <div className="col-12 my-1 text-end">
                        <StyledLink theme={theme} to={ `/about#contact` } >Besoin d'aide ?</StyledLink>
                    </div>
                    <div className="col-12 text-end my-3">
                        { load === 0 ? 
                                <button type="submit" className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5">{ status === 1 ? <span>Bienvenue sur BookEasily !</span>  : <span>Inscription</span>  }</button>
                            :
                                <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                        }
                    </div>
                    <div className="col-12">
                        <p className="text-muted">Vous avez déjà un compte ? <Link to='/sign-in' className="text-warning">Connectez-vous !</Link></p>
                    </div>
                </form>
            </LogSection>
            <HelpButton />
        </Fragment>
    )
}

export default Register