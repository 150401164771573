import React, { Fragment } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom'
import { ThemeProvider } from './context'
import GlobalStyle from './styles/globalStyles'
import Home from './pages/public/home'
import About from './pages/public/about'
import BestDeals from './pages/public/bestdeals'
import Checkout from './pages/public/checkout'
import Partners from './pages/public/partners'
import Properties from './pages/public/properties'
import Property from './pages/public/property'
import Results from './pages/public/results'
import AdsUpdate from './pages/dashboard/adsUpdate'
import AvailableUpdate from './pages/dashboard/availableUpdate'
import BookingUpdate from './pages/dashboard/bookingUpdate'
import CreateHotel from './pages/dashboard/createHotel'
import CreateLocation from './pages/dashboard/createLocation'
import CreateMeuble from './pages/dashboard/createMeuble'
import Dashboard from './pages/dashboard/dashboard'
import PasswordUpdate from './pages/dashboard/passwordUpdate'
import Profil from './pages/dashboard/profil'
import ShowHotels from './pages/dashboard/showHotels'
import ShowLocations from './pages/dashboard/showLocations'
import ShowMeubles from './pages/dashboard/showMeubles'
import UserUpdate from './pages/dashboard/userUpdate'
import Login from './pages/login'
import Register from './pages/register'
import EmailVerify from './pages/emailVerify'
import EmailVerifyHash from './pages/emailVerifyHash'
import Error from './composants/public/widgets/error'
import Success from './pages/public/success'

const router = createBrowserRouter (
    createRoutesFromElements (
        <Fragment>
            <Route path="/" element={<Home />} errorElement={<Error/>} />
            <Route path="/partners" element={<Partners /> } errorElement={<Error/>} />
            <Route path="/sign-in" element={<Login /> } errorElement={<Error/>} />
            <Route path="/sign-up" element={<Register /> } errorElement={<Error/>} />
            <Route path="/email/verify/:param_id/:param_hash" element={<EmailVerifyHash /> } errorElement={<Error/>} />
            <Route path="/email/verification-notification" element={<EmailVerify /> } errorElement={<Error/>} />
            <Route path="/results/q=" element={<Results /> } errorElement={<Error/>} />
            <Route path="/properties/:param/:param_name/:param_id" element={<Properties />} errorElement={<Error/>} />
            <Route path="/:param_cat/property/:param_name/:param_id" element={<Property /> } errorElement={<Error/>} />
            <Route path="/checkout/:param_id/:entering_at/:living_at/:number_persons/:n_pieces/:total_price/:param_designation/:param_category/:param_phone/:param_type/:param_town/:param_street/:param_duration" element={<Checkout /> } errorElement={<Error/>} />                     <Route path="/check/property/:param_id" element={<Property /> } />
            <Route path="/about" element={<About /> } errorElement={<Error/>} />
            <Route path="/checkout/success/:param_phone" element={<Success /> } errorElement={<Error/>} />
            <Route path="/best_deals" element={<BestDeals /> } errorElement={<Error/>} />
            <Route path="/dashboard" element={<Dashboard />} errorElement={<Error/>} />
            <Route path="/dashboard/user" element={<Profil />} errorElement={<Error/>} />
            <Route path="/dashboard/user/update/:param_id" element={<UserUpdate />} errorElement={<Error/>} />
            <Route path="/dashboard/user/update/password/:param_id" element={<PasswordUpdate />} errorElement={<Error/>} />
            <Route path="/dashboard/booking/update/:param_id" element={<BookingUpdate />} errorElement={<Error/>} />
            <Route path="/dashboard/availability/update/:param_id" element={<AvailableUpdate />} errorElement={<Error/>} />
            <Route path="/dashboard/categories/hotels/new_ads" element={<CreateHotel />} errorElement={<Error/>} />                      
            <Route path="/dashboard/categories/meubles/new_ads" element={<CreateMeuble />} errorElement={<Error/>} /> 
            <Route path="/dashboard/categories/non_meubles/new_ads" element={<CreateLocation />} errorElement={<Error/>} />
            <Route path="/dashboard/categories/hotels/views_ads" element={<ShowHotels />} errorElement={<Error/>} />                      
            <Route path="/dashboard/categories/meubles/views_ads" element={<ShowMeubles />} errorElement={<Error/>} /> 
            <Route path="/dashboard/categories/non_meubles/views_ads" element={<ShowLocations />} errorElement={<Error/>} />
            <Route path="/dashboard/categories/update_ads/:param_id" element={<AdsUpdate />} errorElement={<Error/>} />
            <Route path="*" element={<Error/>} />
        </Fragment>
    )
)

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <ThemeProvider>
            <GlobalStyle />
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
);