// Home

export const settingsCenterMode321 = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
  }
  
  export const settingsCenterMode21 = {
    centerMode: true,
    infinite: false,
    centerPadding: "10px",
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
  }
  
  export const settingsLinear321 = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10px",
    autoplay: true,
    speed: 500,
    autoplaySpeed: 12000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }
  
  export const settingsLinear21 = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10px",
    autoplay: true,
    speed: 500,
    autoplaySpeed: 12000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }
  
  export const settingsFade = {
    className: "center",
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
  }
  
  // Results
  
  export const settingsDefault321 = {
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay : true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }
  
  //singleproperty
  export const settingsDefault21 = {
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay : true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }
  
  // nextArrow: <SampleNextArrow />,
  //prevArrow: <SamplePrevArrow />,
  
  //function SampleNextArrow(props) {
    //  const { className, style, onClick } = props;
      //return (
        //<div
          //className={className}
          //style={{ ...style, display: "flex", background: "#df9600" }}
          //onClick={onClick}
        ///>
      //);
  //}
  
  //function SamplePrevArrow(props) {
    //  const { className, style, onClick } = props;
      //return (
        //<div
          //className={className}
          //style={{ ...style, display: "flex", backgroundColor: "#df9600"}}
          //onClick={onClick}
        ///>
      //);
  //}