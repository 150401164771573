import { Fragment } from "react"
import { Link, useParams } from "react-router-dom"
import { useTheme } from "../../../hooks"
import Header from "../../../composants/public/sections/header"
import { HeroSection, MainContainer } from "../../../styles/atoms"
import Footer from "../../../composants/public/sections/footer"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"

function Success() {
    const { theme } = useTheme()
    document.title = 'BookEasily | Réservation effectué avec success'
    const { param_phone } = useParams()

    return (
        <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <HeroSection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Félicitations ! 🎉</h2>
                        <div className="col-lg-8 mx-auto">
                            <p className="lead mb-4">Votre réservation a été effectué avec succès. Cliquez sur le lien ci-dessous pour contacter le propriétaire via WhatsApp !</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <Link to={`https://wa.me/${param_phone}`} type="button" className="btn btn-warning btn-md fw-bold px-4 rounded-5">☎️ {param_phone}</Link>
                            </div>
                        </div>
                    </div>
                </HeroSection>
            </MainContainer>
            <Footer />
            <MobileBottomHeader />
        </Fragment>
    )
}

export default Success