import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useTheme } from "../../../hooks"
import { RowSection, SponsorSection, StyledTitle, TitlePlusSection } from "../../../styles/atoms"
import CardAds from '../cards/cardAds'
import { settingsCenterMode321 } from '../../../tools'

function Sponsors({annonces}) {
    const { theme } = useTheme()

    return (
        <SponsorSection theme = {theme}>
            <TitlePlusSection>
                <StyledTitle theme={theme}>Recommandations<i className="bi bi-stars text-warning"></i></StyledTitle>
                <p className="visually-hidden">Découvrez à l’accueil de BookEasily les catégories d’hébergements à savoir hôtels, meublés, locations/non meublés. Vous vous avez la possibilité de consulter directement les logements qui se trouvent à l’accueil. Ils sont classés par catégorie !</p>
            </TitlePlusSection>
            <RowSection>
                <div className="col-12 slider-container">
                  <Slider {...settingsCenterMode321}>
                  {annonces.map(({standing, id, designation, free_booking, free_cancellation, name, category, type, town, street, cover, prices, view, description, is_new, commission, visibility, ref}) => visibility === 1 ? 
                      (
                        <CardAds key={ref} standing={standing} free_booking={free_booking} free_cancellation={free_cancellation} designation={designation} id={id} name={name} category={category} type={type} town={town} street={street} cover={cover} price={prices} view={view} description={description} is_new={is_new} reff={ref} />
                      ) : null
                    ) 
                  }
                  </Slider>
                </div>
            </RowSection>
        </SponsorSection>
    )
}

export default Sponsors