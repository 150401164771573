import styled from 'styled-components'

export const Button = styled.a`
    position: fixed;
    width: 100%;
    left: 95%;
    bottom: 85px;
    height: 10px;
    font-size: 1.4rem;
    z-index: 1;
    cursor: pointer;
    color: #df9600;

    &:active {
        color: #df9600;
    }

    &:hover {
        color: #df9600;
    }

    @media (max-width: 540px) {
        left: 86%;
        bottom: 140px;
    }
`
