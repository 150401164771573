import React from "react"
import { Link } from "react-router-dom"
import { CatLink } from "../../../styles/atoms"

function CardTowns({id, cover, town, count, description} ) {
    
    return (
        <CatLink to={`/properties/towns/${town}/${id}`} key={`${id}-${town}`}>
            <div className="row g-0">
                <div className="col m-1">
                    <div className="card p-1 text-bg-dark" style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${cover})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                        <div className="d-flex flex-column py-5 px-3 pb-3 text-shadow-1">
                            <h2 className="py-3 mt-5 mb-3 lead-5 rounded-5 fw-bold">{town} </h2>
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <p className="card-text lead-1">{description}</p>
                                    { count > 0 ? 
                                        <Link type="button" className="btn btn-success fw-bold" to={`/properties/towns/${town}/${id}`}>Consultez {count} annonces</Link>
                                        :
                                        <Link type="button" className="btn btn-success fw-bold disabled">Bientot disponible</Link>
                                    } 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </CatLink>
    )
}

export default CardTowns