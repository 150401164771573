import { Fragment, useState } from "react"
import { Link,  } from "react-router-dom"
import { HomeLogo } from "../../styles/atoms"
import logo from '../../assets/logo.png'
import HelpButton from "../../composants/public/widgets/helpButton"

function Login () {
    document.title = `BookEasily | Se connecter`

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [status, setStatus] = useState('')
    const [load, setLoad] = useState(0)

    const forms = new FormData()
    forms.append('email', email)
    forms.append('password', password)
    
    async function handleLogin(e, forms) {
        e.preventDefault()
        setLoad(1)

            await fetch('https://api.book-easily.com/api/login', {
                method: "POST",
                mode: "cors",
                cache: "default",
                credentials: "include",
                redirect: 'manual',
                body: forms,
                headers: {
                    'Accept': 'application/json; charset=UTF-8',
                },
            })
            .then((response) => response.json())
            .then((data) => {
                setStatus(data?.status)
                setLoad(0)
                if (data?.status === 1) {
                    localStorage.setItem("token", JSON.stringify(data?.data.token))
                    localStorage.setItem("user", JSON.stringify(data?.data.user))
                    const xsrf_token = document.cookie
                    localStorage.setItem("cookies", xsrf_token.split('=')[1])
                    data.data.user.email_verified === 1 ? window.location.assign("/dashboard") : window.location.assign("/email/verification-notification")
                }
            })
            .catch((err) => {
                setLoad(0)
                console.log(err.message)
            })
    }

    return (
        <Fragment>
            <div className='container justify-content-center col-md-6 offset-md-3 col-12'>
                <form className="my__form g-2 p-3 my-5" onSubmit={(e) => handleLogin(e, forms)}>
                    <div className="col-md-12 text-center">
                        <Link to="/">
                            <HomeLogo src={logo} alt="logo bookEasily" />
                        </Link>
                    </div>
                    { status === 1 && 
                        (<div className="alert alert-info text-center" role="alert">
                            Vous etes de nouveau connecté {JSON.parse(localStorage.getItem("user")).first_name} !
                        </div>)
                    }
                    { status === 0 && 
                        (<div className="alert alert-danger text-center" role="alert">
                            Vérifiez à nouveau vos informations. 
                        </div>)
                    }
                    <div className="col-md-12 py-1">
                        <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/" className='text-warning'> Accueil</Link>
                    </div>
                    <div className="col-md-12 py-1">
                        <input type="email" className="form-control rounded-5" id="email" name="email" placeholder="Entrez votre email..." value={email} onChange={(e) => setEmail(e.target.value) } required/>
                        <small className="text-muted">Email *</small>
                    </div>
                    <div className="col-md-12 py-1">
                        <input type="password" className="form-control rounded-5" id="password" name="password" placeholder="Entrez votre mot de passe..." value={password} onChange={(e) => setPassword(e.target.value) } required/>
                        <small className="text-muted">Mot de passe *</small>
                    </div>
                    <div className="col-12 my-1 text-warning">
                        * = champ obligatoire
                    </div>
                    <div className="col-12 text-end">
                        <Link to="/" className="text-warning">Mot de passe oublié ?</Link>
                    </div>
                    <div className="col-12 text-end my-3">
                        { load === 0 ? 
                                <button type="submit" className="btn btn-outline-warning fw-bold w-100 rounded-5">Connexion</button>
                            :
                                <button className="btn btn-outline-warning fw-bold w-100 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                        }
                    </div>
                    <div className="col-12">
                        <p className="text-muted">Vous n'avez pas de compte ? <Link to='/sign-up' className="text-warning">Créer un compte !</Link></p>
                    </div>
                </form>
            </div>
            <HelpButton />
        </Fragment>
    )
}

export default Login