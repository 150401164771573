import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useFetch, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import Header from "../../../composants/public/sections/header"
import { GridProperty, HeroProperty, MainContainer, PaginateLink, PropertySection, StyledTitle1 } from "../../../styles/atoms"
import Loading from "../../../composants/public/widgets/loading"
import CardAds from "../../../composants/public/cards/cardAds"
import Badge from "../../../composants/public/sections/badge"
import Filter from "../../../composants/public/sections/filter"
import Deals from "../../../composants/public/sections/deals"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"
import FilterButton from "../../../composants/public/widgets/filterButton"

function Results () {
  document.title = `BookEasily | Consultez nos logements disponibles`

  const { theme } = useTheme()
  // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const x_xsrf_token = localStorage.getItem("cookies")

  const [results, setResults] = useState()
  const [metaLinks, setMetaLinks] = useState()

  const { isLoading11, data11, error11 } = useFetch(
    `https://api.book-easily.com/api/ads?per_page=20`,
    token
  )
  const countAds = data11?.meta?.total

  const path = window.location.href.split('q=')[1]
  
  useEffect(() => {
    const getData = async (path, token, x_xsrf_token) => {
      const response = await fetch(
        `https://api.book-easily.com/api/search${path}`, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
              'Authorization': `Bearer ${token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Accept": 'application/json',
              'X-XSRF-TOKEN': `${x_xsrf_token}`
          }
        }
      )
      const data = await response.json()
      if (data?.status === 1) {
        setResults(data?.data)
        setMetaLinks(data?.meta?.links)
      }
    }
    getData(path, token, x_xsrf_token)
  }, [path, token, x_xsrf_token])

  async function handlePaginate (e, url, token, x_xsrf_token){
    e.preventDefault()

    await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "default",
      credentials: "include",
      
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'X-XSRF-TOKEN': `${x_xsrf_token}`
      }, 
    })
    .then((response) => response.json())
    .then((data) => {
      if (data?.status === 1) {
          setResults(data?.data)
          setMetaLinks(data?.meta?.links)
      }
    })
    .catch((err) => {
      console.log(err.message)
    })
  }

  console.log(results)
  
  if (error11) {
    return (
      <Error/>
    )
  }

    return  (
      <Fragment>
          {/* Header Section */}
          <Header/>

          <MainContainer theme={theme}>
          {/* { user.email_verified === 0 && 
              (<div className="alert alert-danger text-center" role="alert">
                  Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
              </div>)
          } */}
            {/* Hero Section */}
            <HeroProperty theme={theme}>
              <div className="row">
                <StyledTitle1 theme={theme}>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                      <li className="breadcrumb-item text-warning">Resultats </li>
                    </ol>
                  </nav>
                </StyledTitle1>
              </div>
            </HeroProperty>
    
            {isLoading11 ? (
            <Loading />
            ) : (
            <Fragment>
              {/* Property Section */}
              <PropertySection>
                  <GridProperty>
                    {results.map(({id, standing, free_booking, free_cancellation, name, designation, category, type, town, street, cover, prices, view, description, is_new, ref}) => 
                      <CardAds key={ref} standing={standing} free_booking={free_booking} free_cancellation={free_cancellation} designation={designation} id={id} name={name} category={category} type={type} town={town} street={street} cover={cover} price={prices} view={view} description={description} is_new={is_new} reff={ref} />     
                    )} 
                  </GridProperty>
              </PropertySection>
              <nav aria-label="..." className="my-3">
                <ul className="pagination justify-content-center"> 
                {metaLinks.map(({url, label, active}) => active ?
                  (<li className="page-item" key={label}>
                    { url ? 
                      <PaginateLink className='page-link' style={{ backgroundColor: '#DF9600', color: 'white' }} onClick={(e) => handlePaginate(e, url, token, x_xsrf_token)}>{label}</PaginateLink>
                    : 
                      null
                    }
                  </li>) :
                  
                  (<li className="page-item" key={label}>
                    { url ? 
                      <PaginateLink className='page-link' onClick={(e) => handlePaginate(e, url, token, x_xsrf_token)}>{label}</PaginateLink>
                    : 
                      null
                    }
                  </li> )
                )}
                </ul>
              </nav>

              <Badge />
              <Filter countAds={countAds} />
                
              <Deals />
            
            </Fragment> 
          )}

          </MainContainer>
      
        {/* Footer Section */}
        <Footer />
        <FilterButton />
        <ScrollButton />
        <MobileBottomHeader/>
      </Fragment>
    )
}

export default Results