import { Fragment, useEffect, useState } from 'react'
import { Link, useParams  } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import logo from '../../../assets/logo.png'
import { GridBoardForm, HomeLogo, LogSection } from '../../../styles/atoms'
import HelpButton from '../../../composants/public/widgets/helpButton'

function UserUpdate () {
    document.title = `BookEasily | Modifiez vos informations`
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const x_xsrf_token = localStorage.getItem("cookies")

    const { param_id } = useParams()

    const [last_name, SetLastName] = useState('')
    const [first_name, SetFirstName] = useState('')
    const [value, setValue] = useState()
    const [country, setCountry] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [photo, setPhoto] = useState('')
    const [role, setRole] = useState('')
    const [status, setStatus] = useState('')
    const [load, setLoad] = useState(0)

    useEffect(() => {setAddress(user.address)}, [user.address])
    useEffect(() => {setCountry(user.country_id)}, [user.country_id])
    useEffect(() => {setEmail(user.email)}, [user.email])
    useEffect(() => {SetLastName(user.last_name)}, [user.last_name])
    useEffect(() => {SetFirstName(user.first_name)}, [user.first_name])
    useEffect(() => {setValue(user.phone)}, [user.phone])
    useEffect(() => {setRole(user.role_id)}, [user.role_id])

    if (role === 2) {
        setRole('Client')
    }
    if (role === 3) {
        setRole('Partenaire')
    }
    if (country === 1) {
        setCountry('Cameroun')
    }

    const forms = new FormData()
    last_name !== user.last_name && forms.append('last_name', last_name)
    first_name !== user.first_name && forms.append('first_name', first_name)
    country && forms.append('country', country)
    value !== user.phone && forms.append('phone', value)
    email !== user.email && forms.append('email', email)
    address !== user.address && forms.append('address', address)

    if (photo) {
        forms.append('photo', photo[0])
    } 
     
    async function handleRegister(e, param_id) {
        e.preventDefault() 
        setLoad(1)

        await fetch(`https://api.book-easily.com/api/users/${param_id}`, {
            method: "POST",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json; charset=UTF-8',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            },
            body: forms,
        })
        .then((response) => response.json())
        .then((data) => {
            setStatus(data?.status)
            setLoad(0)
            if (data?.status === 1) {
                const xsrf_token = document.cookie
                localStorage.setItem("cookies", JSON.stringify(xsrf_token.split('=')[1]))
                localStorage.setItem("user", JSON.stringify(data?.data))
                window.location.assign("/dashboard/user")
            }
        })
        .catch((err) => {
            setLoad(0)
           console.log(err.message)
        })
    } 

    const upload = (e) => {
        const fileInput = document.getElementById('photo');
        const files = fileInput.files;
        setPhoto(files)
    }

    return (
        <Fragment>
            <LogSection>
                <form className="my__form g-2 p-3 my-5 col-md-8" method="POST" onSubmit={(e) => handleRegister(e, param_id)}>
                    <div className="col-md-12 text-center">
                        <Link to="/dashboard">
                            <HomeLogo src={logo} alt="logo BookEasily" />
                        </Link>
                    </div>
                    { status === 1 && 
                        (<div className="alert alert-info" role="alert">
                            Modifications éffectuées avec succès ! Voir votre <Link to='/dashboard' >tableau de bord</Link>. 
                        </div> )
                    }
                    { status === 0 && 
                        (<div className="alert alert-danger" role="alert">
                            Vérifiez à nouveau vos informations. 
                        </div>)
                    }
                    <div className="col-12 my-1">
                        <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/dashboard" className='text-warning'> Accueil</Link>
                    </div>
                    <GridBoardForm>
                        <div className="col-12 my-1">
                            <input type="text" className="form-control rounded-5" id="last_name" name="last_name" placeholder="Entrez votre nom..." value={last_name} onChange={(e) => SetLastName(e.target.value) }/>
                            <small className="text-muted">Nom</small>
                        </div>
                        <div className="col-12 my-1">
                            <input type="text" className="form-control rounded-5" id="first_name" name="first_name" placeholder="Entrez votre prénom..." value={first_name} onChange={(e) => SetFirstName(e.target.value) }/>
                            <small className="text-muted">Prénom</small>
                        </div>
                        <div className="col-12 my-1">
                            <input type="email" className="form-control rounded-5" disabled id="email" name="email" placeholder="Entrez votre email..." value={email} onChange={(e) => setEmail(e.target.value) }/>
                            <small className="text-muted">Email</small>
                        </div>
                        <div className="col-12 my-1">
                            <select className="form-select rounded-5" id="role" name="role" disabled>
                                <option value="">Type de compte</option>
                                <option value='client' >Client</option>
                                <option value='admin'>Partenaire</option>
                            </select>
                            <small className="text-muted">Type de compte : {role}</small>
                        </div>
                    </GridBoardForm>
                        <div className="col-12 col-12 my-1">
                            <input type="file" accept="image/png, image/jpeg, image/jpg, image/webp, image/tiff, image/svg" className="form-control rounded-5" id="photo" name="photo" placeholder="Entrez une photo de profil..." onChange={upload} />
                            <small className="text-warning">Photo de profil</small>
                        </div>
                    <GridBoardForm>
                        <div className="col-12 my-1">
                            <input type="text" className="form-control rounded-5" id="country" name="country" value={country} placeholder="Entrez le nom de votre pays de résidence..." onChange={(e) => setCountry(e.target.value) }/>
                            <small className="text-muted">Pays</small>
                        </div>
                        <div className="col-12 my-1">
                            <input type="text" className="form-control rounded-5" id="address" name="address" value={address} placeholder="Entrez votre adresse de résidence..." onChange={(e) => setAddress(e.target.value) }/>
                            <small className="text-muted">Adresse</small>
                        </div>
                    </GridBoardForm>
                        <div className="col-12 my-1">
                            <PhoneInput id="phone" name="phone" className="form-control rounded-5"
                                placeholder="Sélectionner l'indicatif de votre pays et saisir votre numéro..."
                                value={value}
                                onChange={setValue}/>
                            <small className="text-muted">Numéro de téléphone</small>
                        </div>
                    <div className="col-12 text-end my-3">
                    { load === 0 ? 
                        <button type="submit" className="btn btn-outline-warning fw-bold w-100 rounded-5">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Mettre à jour</span>  }</button>
                        :
                            <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En Cours...
                            </button>
                    }
                    </div>
                </form>
            </LogSection>
            <HelpButton />
        </Fragment>
    )
}

export default UserUpdate