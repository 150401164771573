import { Fragment, useState } from "react"
import { useTheme } from "../../hooks"
import { DeleteButton, PaginateLink, StyledLink, StyledTitle2, TD } from "../../styles/atoms"

function Bookings({group, datas}) {
    const { theme } = useTheme()
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const x_xsrf_token = localStorage.getItem("cookies")
    const [books, setBooks] = useState(datas?.data)
    const [metaLinks, setMetaLinks] = useState( datas?.data !== null && datas?.meta?.links)
    const [ cancellation_reason, setCancellationReason ] = useState('')
    const [ param_id, setParamId ] = useState('')
    const date = new Date().toISOString()

    const formDelete = new FormData()
    formDelete.append("cancellation_reason", cancellation_reason)

    async function handleDeleteBooking (e, param_id, formDelete, token, x_xsrf_token){
        e.preventDefault()

        await fetch(`https://api.book-easily.com/api/cancel/booking/${param_id}`, {
            method: 'POST',
            mode: "cors",
            cache: "default",
            credentials: "include",
            body: formDelete,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
                },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.status === 1) {
                window.location.reload()
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    const formCancel = new FormData()
    formCancel.append("cancellation_reason", 'absence du client')

    async function handleCancelBooking (e, param_id, formCancel, token, x_xsrf_token){
        e.preventDefault()

        await fetch(`https://api.book-easily.com/api/cancel/booking/${param_id}`, {
            method: 'POST',
            mode: "cors",
            cache: "default",
            credentials: "include",
            body: formCancel,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
                },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.status === 1) {
                window.location.reload()
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    async function handlePaginate (e, url, token, x_xsrf_token){
        e.preventDefault()

        await fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "default",
            credentials: "include",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.status === 1) {
                setBooks(data?.data)
                setMetaLinks(data?.meta?.links)
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    return (
    <Fragment>
        <div className="table-responsive my__card card mt-3">
            <table className="table table-sm">
            <caption className="m-3"><StyledTitle2 theme={theme}>{group}</StyledTitle2></caption>
            <thead className="bg-warning text-capitalize">
                <tr>
                <th scope="col" className="p-2">Statut</th>
                <th scope="col" className="p-2">Voir</th>
                { user.role_id === 2 ? <th scope="col" className="p-2">Modifier</th> : null }
                { user.role_id === 2 ? <th scope="col" className="p-2">Annuler</th> : null }
                <th scope="col" className="p-2">Fait le</th>
                <th scope="col" className="p-2">arrivée</th>
                <th scope="col" className="p-2">départ</th>
                <th scope="col" className="p-2">Montant</th>
                <th scope="col" className="p-2">Nombre de pièces</th>
                <th scope="col" className="p-2">Noms</th>
                <th scope="col" className="p-2">Prénoms</th>
                <th scope="col" className="p-2">Pour</th>
                <th scope="col" className="p-2">Nombre de personnes</th>
                </tr>
            </thead>
            <tbody> 
            {books ? books.map(({id, other, canceled, last_name, else_full_name, first_name, number_persons, n_pieces, total_price, ad_id, entering_at, living_at, transaction_date, ref, out_book}) =>  out_book === 0 &&
                <tr key={ref}>
                    <TD theme={theme}>
                        {(() => {
                            let limitDate = new Date(living_at)
                            limitDate = limitDate.toISOString()
                            if (canceled === 1) {
                                return (<span>Annulé</span>)
                            } else {
                               if (date > limitDate) {
                                return (<span>Terminé</span>)
                               } else {
                                return (
                                    <Fragment>
                                        <span>En Cours ?</span>
                                        <form action="" className="d-flex justify-content-between">
                                            <div className="p-2 d-flex justify-content-between">
                                                <input type="radio" name="book" id="oui" value="Oui" checked/>
                                                <label htmlFor="oui" className="p-1">Oui</label>
                                            </div>
                                            <div className="p-2 d-flex justify-content-between">
                                                <input type="radio" name="book" id="non" value="Non" onClick={(e) => handleCancelBooking(e, id, formCancel, token, x_xsrf_token)}/>
                                                <label htmlFor="non" className="p-1"> Non</label>
                                            </div>
                                        </form>
                                    </Fragment>
                                )
                               }
                            }
                        })()}
                    </TD>
                    {/* <TD theme={theme}>{  date === new Date(living_at).toLocaleString ? (<span>Terminé</span>) : (canceled === 1 ? <span>Annulé</span> : <span>En cours</span>) }</TD>    */}
                    <TD theme={theme}><StyledLink target="_blank" $theme={theme} to={`/check/property/${ad_id}`}> <i className="bi bi-eye-fill"></i></StyledLink></TD>

                    { user.role_id === 2 ? 
                    <Fragment>
                    <TD theme={theme}>
                        <StyledLink $theme={theme} to={`/dashboard/booking/update/${id}`}>
                            <i className="bi bi-pencil-fill"></i>
                        </StyledLink>
                    </TD>
                    <TD theme={theme}>
                        { canceled === 1 ? 
                            <DeleteButton className="disabled"><i className="bi bi-x-circle-fill"></i></DeleteButton> 
                        : 
                            <DeleteButton onClick={(e) => {setParamId(id)}} data-bs-toggle="modal" data-bs-target="#deleteBooking"><i className="bi bi-x-circle-fill"></i></DeleteButton>
                        }
                    </TD>
                    </Fragment>
                    : null }

                    <TD theme={theme}>
                        {(() => {
                        let date = new Date(transaction_date)
                        date = date.toLocaleString()
                        return date;
                        })()}
                    </TD>
                    <TD theme={theme}>
                        {(() => {
                        let date = new Date(entering_at)
                        date = date.toLocaleString()
                        return date;
                        })()}
                    </TD>
                    <TD theme={theme}>
                        {(() => {
                        let date = new Date(living_at)
                        date = date.toLocaleString()
                        return date;
                        })()}
                    </TD>
                    <TD theme={theme}>{total_price * n_pieces} </TD>
                    <TD theme={theme}>{n_pieces} </TD>
                    <TD theme={theme}>{last_name} </TD>
                    <TD theme={theme}>{first_name} </TD>
                    <TD theme={theme}>{ other === 0 ? <span>Lui</span> : <span>{else_full_name}</span> }</TD>
                    <TD theme={theme}>{number_persons} </TD>
                </tr>
                ) : null}
            </tbody>
            </table>
        </div>
        <nav aria-label="..." className="mb-5 mt-3">
            <ul className="pagination justify-content-center"> 
            { metaLinks && metaLinks.map(({url, label, active}) => active ?
                (<li className="page-item" key={label}>
                { url ? 
                    <PaginateLink className='page-link' style={{ backgroundColor: '#DF9600', color: 'white' }} onClick={(e) => handlePaginate(e, url, token, x_xsrf_token)}>{label}</PaginateLink>
                : 
                    null
                }
                </li>) :
                
                (<li className="page-item" key={label}>
                { url ? 
                    <PaginateLink className='page-link' onClick={(e) => handlePaginate(e, url, token, x_xsrf_token)}>{label}</PaginateLink>
                : 
                    null
                }
                </li> )
            )}
            </ul>
        </nav>

        <div className="modal fade" id="deleteBooking" tabIndex="-1" aria-labelledby="deleteBookingModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="deleteBookingModal">Annulation de la réservation N°{param_id}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => handleDeleteBooking(e, formDelete, param_id, token, x_xsrf_token) }>
                            <input type="text" name="cancellation_reason" className="form-control my-2" placeholder="Raison de votre annulation" id="cancellation_reason" onChange={(e) => {setCancellationReason(e.target.value)}} required />
                            <button type="submit" className="btn w-100 btn-danger rounded-3 fw-bold me-2"><i className="bi bi-x-circle-fill"></i> Annulez votre annonce</button> 
                        </form>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export default Bookings