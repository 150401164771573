import { Fragment} from "react"
import { Link  } from "react-router-dom"
import { useFetch, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import { AdminSection, ErrorSection, HeaderContainer, HeroAdminSection, HeroTitleAdmin, MainContainer, StyledLink, StyledTitle2 } from "../../../styles/atoms"
import Loading from "../../../composants/public/widgets/loading"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"
import Header from "../../../composants/public/sections/header"
import Admin from "../../../composants/dashboard/header"
import Bookings from "../../../composants/dashboard/bookings"
import AvailableAds from "../../../composants/dashboard/availableAds"
import Stats from "../../../composants/dashboard/stats"
import Column from "../../../composants/dashboard/column"

function Dashboard () {
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null

  document.title = `BookEasily | ${ user ? user.first_name :  null } - Tableau de bord`

  const { theme } = useTheme()

  const { isLoading11, data11, error11 } = useFetch(
    `https://api.book-easily.com/api/${ user.role_id === 3 ? `admin` : `user` }/bookings`,
    token
  ) 

  const bookings = data11
  const countBookings = data11?.meta?.total

  if (error11) {
    return (
      <Error />
    )
  }
    return user.email_verified === 1 ? (
      <Fragment>
        {/* Hearder Section  */}
          <Admin />
        
          <MainContainer theme={theme}>
            <HeroAdminSection theme={theme}>
              <HeroTitleAdmin theme={theme}>Bienvenue { user ? user.first_name :  null } ! </HeroTitleAdmin>
              <div className="row px-3">
                <StyledTitle2 theme={theme}>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/dashboard"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                      <li className="breadcrumb-item text-warning" aria-current="page">Tableau de bord </li>
                    </ol>
                  </nav>
                </StyledTitle2>
              </div>
            </HeroAdminSection>

            
            <AdminSection>
              <div className="col-md-4 col-lg-3">
                <Column theme={theme} />
              </div>
              
              <div className="col-md-8 col-lg-9"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                
              { isLoading11 ? (
              <Loading />
              ) : (
                <Fragment>
                  { user.role_id === 3 ? 
                  <Stats countBookings={countBookings} />
                  : null }

                  <Bookings group="Reservations" datas={bookings} />
                  
                  { user.role_id === 3 ? 
                  (<Fragment> 
                    <AvailableAds group="Période d'indisponibilité" datas={bookings} />
                  </Fragment> 
                  ) : null}
                </Fragment>
              )}  
              </div> 
            </AdminSection>
          </MainContainer>
        <Footer />
        <ScrollButton />
        <MobileBottomHeader/>
      </Fragment>
      ) : (
      <Fragment>
        <HeaderContainer theme = {theme}>
          <Header/>
        </HeaderContainer>
        <MainContainer theme = {theme}>
          <ErrorSection theme={theme}>
            <h3>Oups, vous devez avoir un compte vérifié pour accéder à cette page !</h3>
            <StyledLink theme={theme} to='/sign-up'>Créer votre compte</StyledLink> 
          </ErrorSection>
        </MainContainer>
        <Footer />
        <ScrollButton />
        <MobileBottomHeader/>
      </Fragment>
  )
}

export default Dashboard