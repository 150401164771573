import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../../hooks";
import Header from "../../../composants/public/sections/header";
import { GridProperty, HeroProperty, MainContainer, PropertySection, StyledTitle1 } from "../../../styles/atoms";
import CallToAction from "../../../composants/public/sections/callToAction";
import Explore from "../../../composants/public/sections/explore";
import Badge from "../../../composants/public/sections/badge";
import Filter from "../../../composants/public/sections/filter";
import CardUpsell from "../../../composants/public/cards/cardUpsell";
import Footer from "../../../composants/public/sections/footer";
import ScrollButton from "../../../composants/public/widgets/scrollUp";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";
import { pubList } from '../../../data/pubList'
import FilterButton from "../../../composants/public/widgets/filterButton";

export default function BestDeals () {
    document.title = `BookEasily | Bons Plans`
    // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  
    const { theme } = useTheme()

    return (
        <Fragment>
        {/* Header Section */}
        <Header/>
        
          <MainContainer theme={theme}>
          {/* { user.email_verified === 0 && 
              (<div className="alert alert-danger text-center" role="alert">
                  Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
              </div>)
          } */}
            {/* Hero Section */}
            <HeroProperty theme={theme}>
              <div className="row">
                <StyledTitle1 theme={theme}>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                      <li className="breadcrumb-item text-white" aria-current="page">Bons Plans </li>
                    </ol>
                  </nav>
                </StyledTitle1>
              </div>
            </HeroProperty>

            <PropertySection>
              <GridProperty>
                {pubList.map(({id, town, cover, title, price, description}) => 
                    <CardUpsell key={id} id={id} town={town} title={title} price={price} cover={cover} description={description} />
                ) 
                }
              </GridProperty>
            </PropertySection>

            <CallToAction />

            <Filter />

            <Badge />

            <Explore />
        </MainContainer>

        {/* Footer Section */}
        <Footer />
        <FilterButton />
        <ScrollButton />
        <MobileBottomHeader/>

        </Fragment>
    )
}