import { Link } from 'react-router-dom';
import img from '../../../assets/Bookeasily.jpg'
import { useTheme } from '../../../hooks';
import { HeroSection, HeroText, HeroTitle } from '../../../styles/atoms';

function Hero() {
    const { theme } = useTheme()
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

    return (
        <HeroSection theme = {theme}>
            <div className="row flex-md-row-reverse align-items-center gy-3 py-4">
                <div className="col-12 col-md-5">
                    <img src={img} loading="lazy"  className="d-block w-100 shadow rounded" alt="BookEasily" />
                </div>
                <div className="col-12 text-center text-sm-start col-md-7">
                    <HeroTitle className='text-white'><span className='text-warning'>BookEasily</span> : réserver devient plus facile et rapide !</HeroTitle>
                    <HeroText className='text-white'>
                        BookEasily est l'interface numérique de réservations d'hotels, meublés et locations
                         en ligne disponible sur l'ensemble du territoire Camerounais et en Afrique.
                    </HeroText>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        { user ?  
                            user.role_id === 3 ?  
                            <Link to='/dashboard' role="button" className="btn btn-outline-warning btn-md px-4 fw-bold rounded-5">Créez votre annonce !</Link>
                            :
                            <Link to='/partners' role="button" className="btn btn-warning btn-md px-4 fw-bold rounded-5">Créez votre annonce !</Link>
                        :  
                        <Link to='/partners' role="button" className="btn btn-warning btn-md px-4 fw-bold rounded-5">Créez votre annonce !</Link>
                         }
                    </div>
                </div>
            </div>
        </HeroSection>
    );
  }
  
  export default Hero
  