import { Fragment, useEffect, useState } from "react"
import { useTheme } from "../hooks"
import Header from "../composants/public/sections/header"
import { EmailVerifySection, MainContainer } from "../styles/atoms"
import Footer from "../composants/public/sections/footer"
import MobileBottomHeader from "../composants/public/sections/mobileBottomHeader"

function EmailVerify() {
    const { theme } = useTheme()
    document.title = 'BookEasily | Confirmez votre email'
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const x_xsrf_token = localStorage.getItem("cookies")
    const [status, setSatus] = useState(0)
    const [load, setLoad] = useState(0)
    
    const [role, setRole] = useState('')
    useEffect(() => {setRole(user.role_id)}, [user.role_id])

    const desc = document.querySelector('meta[name="description"]')

    if (desc) {
        desc.setAttribute('content', 'BookEasily | Devenez partenaire et boostez votre visibilité et vos réservations');
    }

    async function handleEmailVerify(e) {
        e.preventDefault()
        setLoad(1)

        await fetch(`https://api.book-easily.com/api/email/verification-notification`,{
            method: 'POST',
            mode: "cors",
            cache: "default",
            credentials: "include",
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            },
        }
        )
        .then((response) => response.json())
        .then((data) => {
            setSatus(data?.status)
            setLoad(0)
            if (data?.status === 0) {
                window.location.assign("/dashboard") 
            }
        })
    }

    return (
        <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <EmailVerifySection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Confirmez votre mail !</h2>
                        <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">Hello {user.first_name} 🙂, Bienvenue dans la communauté <span className="text-warning">BookEasily</span>, nous sommes très heureux de vous compter parmis { role === 3 ? <span>nos partenaires</span> : <span>nous</span> } !</p>
                        <p className="lead mb-4 fw-bold">Cliquez sur le lien que vous avez reçu dans votre boite mail pour valider votre inscription.</p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        { load === 0 ? 
                                <button type="button" onClick={(e) => handleEmailVerify(e)} className="btn btn-warning btn-md fw-bold px-4 rounded-5">{ status === 1 ? <span>Vous avez reçu un nouveau mail de confirmation</span> : <span>Renvoyez le mail de confirmation</span> }</button>
                            :
                                <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                        }
                        </div>
                        </div>
                    </div>
                </EmailVerifySection>
            </MainContainer>
            <Footer />
            <MobileBottomHeader />
        </Fragment>
    )
}

export default EmailVerify