import { Link } from "react-router-dom"
import { useTheme } from "../../../hooks";
import { ImgCard, SpanCartNew, SpanCartPrices, SpanCartStanding, StyledLink } from '../../../styles/atoms'

function CardAds ({id, name, category, designation, type, town, street, cover, price, view, free_booking, is_new, reff, standing}){
  const { theme } = useTheme()

    return (
      <div className="col p-1" key={ `${reff}` } >
        <div className="card my__card" >
          <div>
            {(() => {
                  let arr = designation && designation.split(' ').join('_');
                  arr = arr && arr.split('&').join('_');
                  arr = arr && arr.split('/').join('_');
                  return (
                    <StyledLink theme = {theme} to={`/${category}/property/${arr}/${id}`}>
                      <ImgCard src={ `${cover[0]}` } loading="lazy"  className="card-img img-fluid" alt= { `${name}-${street}` } />
                    </StyledLink>
                  )
              })()}
            <SpanCartPrices> { category === 'hôtels' && price.days  } { category === 'meublés' && price.days  } { category === 'non meublés' && price.months } XAF </SpanCartPrices>
            { is_new === 1 &&
              <SpanCartNew>Nouveau 🎊</SpanCartNew>
            }
            { standing && 
              <SpanCartStanding>
                  {(() => {
                    const arr = [];
                    for (let i = 0; i < standing; i++) {
                      arr.push(
                        '⭐'
                      );
                    }
                    return arr;
                  })()}
              </SpanCartStanding>
            }
          </div>
          <div className="card-body">
            <h4 className="fw-bold text-capitalize" style={{maxWidth: 320}}>{designation}</h4>
            <p className="card-text text-lowercase text-truncate" style={{maxWidth: 320}}>{name}</p>
          </div>
          <ul className="list-group list-group-flush">
              <li className="list-group-item text-capitalize">{ category === 'non meublés' ? <i className="bi bi-house-door-fill text-warning"></i> : <i className="bi bi-building-fill text-warning"></i> } {type} - {category}</li>
              <li className="list-group-item text-capitalize"><i className="bi bi-geo-alt-fill text-danger"></i> {town} - {street}</li>
              { free_booking === 1 ? <li className="list-group-item"><i className="bi bi-patch-check-fill text-success"></i> Payez sur place</li> : <li className="list-group-item"><i className="bi bi-patch-check-fill text-danger"></i> Réservation payante</li> }
          </ul>
          <div className="card-footer gx-1 row align-items-center">
            <div className="col-3">
              {(() => {
                  let arr = designation && designation.split(' ').join('_');
                  arr = arr && arr.split('&').join('_');
                  arr = arr && arr.split('/').join('_');
                  return (
                    <div className="row g-1">
                      <div className="col fb-share-button" data-href={`https://www.book-easily.com/${category}/property/${arr}/${id}`} data-layout="button" data-size="small">
                          <Link target="_blank" to={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.book-easily.com/${category}/property/${arr}/${id}&src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                              <i className="bi bi-facebook"></i>
                          </Link>
                      </div>
                      <Link className="col" to={`https://api.whatsapp.com/send?text=${type}%20-%20${category}%20de%20${arr}%20à%20${town}%20-%20${street}%20disponible%20sur%20BookEasily.%20Cliquez%20sur%20le%20lien%20suivant%20pour%20consulter%20et%20effectuer%20votre%20reservation%20:%20https://www.book-easily.com/${category}/property/${arr}/${id}`} target="_blank">
                          <i className="bi bi-whatsapp text-success"></i>
                      </Link>
                    </div>
                  )
              })()}
            </div>
            <div className="col-6">
              {(() => {
                  let arr = designation && designation.split(' ').join('_');
                  arr = arr && arr.split('&').join('_');
                  arr = arr && arr.split('/').join('_');
                return (
                    <Link to={`/${category}/property/${arr}/${id}`} className="btn btn-warning w-100 rounded-5 fw-bold">Consultez</Link>
                  )
              })()}
            </div>
            <div className="col-3 text-end">
              <i className="bi bi-eye"> {view}</i>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardAds