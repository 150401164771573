import { Fragment, useEffect, useState } from "react"
import { Link, useParams  } from "react-router-dom"
import PhoneInput from 'react-phone-number-input'
import Header from "../../../composants/public/sections/header"
import Badge from "../../../composants/public/sections/badge"
import Footer from "../../../composants/public/sections/footer"
import HelpButton from "../../../composants/public/widgets/helpButton"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"

function Checkout () {
  document.title = `BookEasily | Reservez votre logement`

  const { param_id, param_phone, entering_at, living_at, number_persons, n_pieces, total_price, param_designation, param_category, param_type, param_town, param_street, param_duration } = useParams()
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const x_xsrf_token = localStorage.getItem("cookies")

  const [last_name, SetLastName] = useState('')
  const [first_name, SetFirstName] = useState('')
  const [else_full_name, setElseFullName] = useState('')
  const [else_phone, setElsePhone] = useState('')
  const [for_me, setForMe] = useState(false)
  const [value, setValue] = useState('')
  const [email, setEmail] = useState('')
  const [else_email, setElseEmail] = useState('')
  const [NPiece, setNPiece] = useState(1)
  const [status, setStatus] = useState('')
  const [price, setPrice] = useState(0)
  const [load, setLoad] = useState(0)
  let start = new Date(entering_at)
  start = start.toLocaleDateString()
  let end = new Date(living_at)
  end = end.toLocaleDateString()

  useEffect(() => { setPrice(NPiece*total_price) }, [NPiece, total_price])

  const forms = new FormData()
  if (!token) {
    forms.append("last_name", last_name)
    forms.append("first_name", first_name)
    forms.append("phone", value)
    forms.append("email", email)
  }
  forms.append("number_persons", number_persons)
  forms.append("entering_at", entering_at)
  forms.append("living_at", living_at)
  forms.append("n_pieces", NPiece)
  forms.append("ad_id", param_id)
  if (for_me === true) {
      forms.append('for_me', 1)
  } 
  if (for_me === false)  {
      forms.append('for_me', 0)
      forms.append('else_full_name', else_full_name)
      forms.append("else_phone", else_phone)
      forms.append("else_email", else_email)
  }

  const url = token ? `https://api.book-easily.com/api/booking` : `https://api.book-easily.com/api/guest/booking`

  async function handleBooking(e, forms, token, url, x_xsrf_token) {
    e.preventDefault()
    setLoad(1)

    await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        body: forms,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept' : 'application/json; charset=UTF-8',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        },
    })
    .then((response) => response.json())
    .then((data) => {
      setStatus(data?.status)
      setLoad(0)
      if (data?.status === 1) {
        window.location.assign(`/checkout/success/${param_phone}`) 
      }
    })
    .catch((err) => {
      setLoad(0)
      console.log(err.message)
    })
  } 

  return (
    <Fragment>
      <Header />
      <Badge />
      <div className="container my-4 bg-white">
        <div className="row my-3">
          
        <div className="col-md-4 mb-5 mb-md-0 order-md-last">
          <div className="card">
              <div className="card-header bg-warning fw-bold">
                Détails de votre reservation
              </div>
              <ul className="list-group list-group-flush">
                  <li className="list-group-item"><span className="fw-bold">🏠 Hébergeur : {param_designation}</span></li>
                  <li className="list-group-item text-capitalize"><i className="bi bi-arrow-right-circle text-warning"></i> {param_type} - {param_category} </li>
                  <li className="list-group-item"><span className="fw-bold"><i className="bi bi-geo text-danger"></i> {param_town} - {param_street} </span> </li>
                  <li className="list-group-item">🕐 Date d'arrivée : {start} </li>
                  <li className="list-group-item">🕐 Date de départ : {end} </li>
                  <li className="list-group-item"><span className="fw-bold">⌛ Durée : {param_duration} </span></li>
                  <li className="list-group-item"><i className="bi bi-arrow-right-circle text-warning"></i> Nombre de logements = {NPiece} </li>
                  <li className="list-group-item fw-bold">💰 Montant = {price} XAF</li>
              </ul>
          </div> 
        </div> 

        <form className="my__card card p-4 offset-lg-1 col-lg-6 col-md-8 order-md-first" onSubmit={(e) => handleBooking(e, forms, token, url, x_xsrf_token)}>
                   
            <div className="form-check my-2 col-12">
                <input className="form-check-input" type="checkbox" checked={for_me} id="for_me" name='for_me' onChange={(e) => setForMe(e.target.checked) }/>
                <label className="form-check-label fw-bold" htmlFor="for_me">
                    Je reserve pour moi
                </label>
            </div>

            { !token && 
            <Fragment>
              <div className="col-12 my-2">
                <label htmlFor="lastName" className="form-label">Nom *</label>
                <input type="text" className="form-control rounded-5" id="lastName" name="lastName" placeholder="votre nom" value={last_name} onChange={(e) => SetLastName(e.target.value) } required/>
              </div>

              <div className="col-12 my-2">
                <label htmlFor="firstName" className="form-label">Prénom *</label>
                <input type="text" className="form-control rounded-5" id="firstName" name="firstName" placeholder="votre prenom" value={first_name} onChange={(e) => SetFirstName(e.target.value) } required/>
              </div>

              <div className="col-12 my-1">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control rounded-5" id="email" name="email" value={email} placeholder="nom@example.com" onChange={(e) => setEmail(e.target.value) }/>
              </div>

              <div className="col-12 my-2">
                <label htmlFor="phone" className="form-label">Téléphone *</label>
                <PhoneInput id="phone" name="phone" className="form-control rounded-5"
                  placeholder="Indicatif de votre pays + numéro..."
                  value={value}
                  defaultCountry='CM'
                  onChange={setValue} required/>
              </div>
            </Fragment>
            }

            <div className="col-12 my-2">
              <label htmlFor="number_pieces" className="form-label">Nombre de pièces (<small className="text-danger"><i className="bi bi-exclamation-triangle-fill"></i> Total disponible : {n_pieces} </small>) </label>
              <div className="input-group">
                <input type="number" className="form-control rounded-5" placeholder="Nombre de pièces" min="1" max={n_pieces} value={NPiece} onChange={(e) => setNPiece(e.target.value) } id="number_pieces" name="number_pieces" />
              </div>
              
            </div>

          { for_me === false ?
          <Fragment>
            <hr className="my-3 text-warning" />
            <div className="col-12 my-2">
              <label htmlFor="else_full_name" className="form-label">Son nom complet *</label>
              <input type="text" className="form-control rounded-5" id="else_full_name" name="else_full_name" placeholder="son nom complet" value={else_full_name} onChange={(e) => setElseFullName(e.target.value) }/>
            </div>

              <div className="col-12 my-2">
                <label htmlFor="else_phone" className="form-label">Son numéro de téléphone *</label>
                <PhoneInput id="else_phone" name="else_phone" className="form-control rounded-5"
                  placeholder="Indicatif de votre pays + numéro..."
                  value={else_phone}
                  onChange={setElsePhone}/>
              </div>

              <div className="col-12 my-2">
                <label htmlFor="else_email" className="form-label">Son email</label>
                <input type="email" className="form-control rounded-5" id="else_email" name="else_email" value={else_email} placeholder="nom@example.com" onChange={(e) => setElseEmail(e.target.value) }/>
              </div>
          </Fragment>
          : null }
          
          <div className="col-12 my-2 text-warning">
            * = champ obligatoire
          </div>
          
          { status === 1 && 
              (<div className="alert alert-info" role="alert">
                  Merci pour votre reservation ! Consultez vos mails pour voir votre facture. <br />
                  <Link to="/">Retour à l'accueil</Link> 
              </div> )
          }
          { status === 0 && 
              (<div className="alert alert-danger" role="alert">
                  Vérifiez à nouveau vos informations. 
              </div>)
          }

          { load === 0 ? 
              <button className="w-100 btn btn-warning fw-bold rounded-5" type="submit">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Reservez votre logement pour {price} XAF</span>  }</button>
          :
              <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  En cours...
              </button>
          }

        </form>

        </div>
      </div>
      <Footer />
      <HelpButton />
      <MobileBottomHeader />
    </Fragment>
  )
}

export default Checkout