import React, {useState} from 'react'
import { Button } from './styles/stylesFilterButton'

const FilterButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

window.addEventListener('scroll', toggleVisible);

return (
	<Button href="#filter">
		<i className="bi bi-search"
		style={{display: visible ? 'inline' : 'none'}} ></i>
	</Button>
);
}

export default FilterButton;
