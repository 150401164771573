import { Fragment, useState } from "react"
import { useParams } from "react-router-dom"
import { useTheme } from "../hooks"
import Header from "../composants/public/sections/header"
import { HeroSection, MainContainer } from "../styles/atoms"
import Footer from "../composants/public/sections/footer"
import MobileBottomHeader from "../composants/public/sections/mobileBottomHeader"

function EmailVerifyHash() {
    const { theme } = useTheme()
    document.title = 'BookEasily | Confirmez votre email'
    const { param_id } = useParams()
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    
    const [status, setStatus] = useState(0)
    const [load, setLoad] = useState(0)

    const desc = document.querySelector('meta[name="description"]')

    if (desc) {
        desc.setAttribute('content', 'BookEasily | Devenez partenaire et boostez votre visibilité et vos réservations');
    }

    const path = window.location.href.split('/')[6]

    async function handleEmailVerifyHash(e, param_id, path) {
        e.preventDefault()
        setLoad(1)

        await fetch(`https://api.book-easily.com/api/email/verify/${param_id}/${path}`,{
            method: 'GET',
            mode: "cors",
            cache: "default",
            credentials: "include",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
            },
        }
      )
      .then((response) => response.json())
      .then((data) => {
        setStatus(data?.status)
        setLoad(0)
          if (data?.status === 1) {
            window.location.assign("/sign-in")
          } else {
            window.location.assign("/email/verification-notofication") 
          }
      })
    }

    return (
        <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <HeroSection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Félicitations ! 🎉</h2>
                        <div className="col-lg-8 mx-auto">
                            <p className="lead mb-4">Cliquez pour aller sur votre espace personnelle.</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            { load === 0 ? 
                                <button type="button" onClick={(e) => handleEmailVerifyHash(e, param_id, path)} className="btn btn-warning btn-md fw-bold px-4 rounded-5"> { status === 1 ? <span>Bienvenue sur BookEasily ! 🎉</span> : <span>Cliquez pour ouvrir votre tableau de bord !</span> }</button>
                            :
                                <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                            }
                            </div>
                        </div>
                    </div>
                </HeroSection>
            </MainContainer>
            <Footer />
            <MobileBottomHeader />
        </Fragment>
    )
}

export default EmailVerifyHash