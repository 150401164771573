import { Fragment, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useFetch22, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import Header from "../../../composants/public/sections/header"
import { GridProperty, HeroProperty, MainContainer, PaginateLink, PropertySection, StyledTitle1 } from "../../../styles/atoms"
import Loading from "../../../composants/public/widgets/loading"
import CardAds from "../../../composants/public/cards/cardAds"
import Badge from "../../../composants/public/sections/badge"
import Filter from "../../../composants/public/sections/filter"
import Deals from "../../../composants/public/sections/deals"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"
import FilterButton from "../../../composants/public/widgets/filterButton"

function Properties (){ 
  const { param, param_name, param_id } = useParams()
  const { theme } = useTheme()
  document.title = `BookEasily | ${param_name}`
  // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const x_xsrf_token = localStorage.getItem("cookies")
  
  const [categories, setCategories] = useState()
  const [metaLinks, setMetaLinks] = useState()

  const { isLoading22, data21, data22, error22 } = useFetch22(
    `https://api.book-easily.com/api/${param}/${param_id}/ads?per_page=12`,
    `https://api.book-easily.com/api/ads?per_page=5`,
    token
  )

  let cat = data21?.data
  let meta = data21?.meta?.links
  let countAds = data22?.data

  useEffect(() => {
    setCategories(cat)
    setMetaLinks(meta)
  }, [cat, meta] )


  async function handlePaginate (e, url, token, x_xsrf_token){
    e.preventDefault()

    await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "default",
        credentials: "include",
        
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        }, 
      })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 1) {
            setCategories(data?.data)
            setMetaLinks(data?.meta?.links)
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  
  if (error22) {
    return (
      <Error/>
    )
  }

  return  (
    <Fragment>
      {/* Header Section */}
      <Header/>
      
        <MainContainer theme={theme}>
        {/* { user.email_verified === 0 && 
              (<div className="alert alert-danger text-center" role="alert">
                  Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
              </div>)
          } */}
          {/* Hero Section */}
          <HeroProperty theme={theme}>
            <div className="row">
              <StyledTitle1 theme={theme}>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                    <li className="breadcrumb-item text-white text-capitalize" aria-current="page">{param_name} </li>
                  </ol>
                </nav>
              </StyledTitle1>
            </div>
          </HeroProperty>
    
          { isLoading22 ? (
            <Loading />
            ) : (
          <Fragment>
            {/* Property Section */}
            <PropertySection>
              <GridProperty>
                  {categories.map(({id, standing, free_booking, free_cancellation, designation, name, category, type, town, street, cover, prices, view, description, is_new, ref}) => 
                    <CardAds key={ref} standing={standing} free_booking={free_booking} free_cancellation={free_cancellation} designation={designation} id={id} name={name} category={category} type={type} town={town} street={street} cover={cover} price={prices} view={view} description={description} is_new={is_new} reff={ref} />     
                  )} 
              </GridProperty>
            </PropertySection>
            <nav aria-label="..." className="my-3">
                <ul className="pagination justify-content-center"> 
                {metaLinks.map(({url, label, active}) => active ?
                  (<li className="page-item" key={label}>
                    { url ? 
                      <PaginateLink className='page-link' style={{ backgroundColor: '#DF9600', color: 'white' }} onClick={(e) => handlePaginate(e, url, token, x_xsrf_token)}>{label}</PaginateLink>
                    : 
                      null
                    }
                  </li>) :
                    
                  (<li className="page-item" key={label}>
                  { url ? 
                    <PaginateLink className='page-link' onClick={(e) => handlePaginate(e, url, token, x_xsrf_token )}>{label}</PaginateLink>
                  : 
                    null
                  }
                </li> )
                )}
                </ul>
              </nav>


            <Badge />

            <Filter countAds={countAds} />
      
            <Deals />
            
          </Fragment>
        ) }
        </MainContainer>

      {/* Footer Section */}
      <Footer />
      <FilterButton/>
      <ScrollButton />
      <MobileBottomHeader/>
    </Fragment>
    )
}

export default Properties
