import { Fragment, useContext } from 'react'
import { Link, Form  } from "react-router-dom"
import img from '../../assets/user.png'
import logo from '../../assets/logo.png'
import { ThemeContext } from '../../context'
import { HeaderContainer, HomeLogo, LogoutButton, MobileSearch, NavLink, SearchForm, StyledLink, UserProfil } from '../../styles/atoms'


function Admin () {
    const { theme } = useContext(ThemeContext)

    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const x_xsrf_token = localStorage.getItem("cookies")

    let user_name = user.first_name

    if (user_name) {
        user_name = user_name.split(" ")[0]
    }

    const logout = async (token) => {
        await fetch(`https://api.book-easily.com/api/logout`, {
            method: "POST",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'X-XSRF-TOKEN': `${x_xsrf_token}`
            }, 
        })
        .then((response) => {
            localStorage.clear();
            window.location.assign('/');
        })
        .catch((err) => {
           console.log(err.message)
        })
    }
     
    const handleLogout = (e) => {
        e.preventDefault() 
        logout(token)
    } 

    return (
        <HeaderContainer theme={theme}>
        <nav className="navbar fixed-top bg-white border-light border-bottom navbar-expand-lg">
            <div className="container-fluid px-md-5">
                <Link to="/">
                    <HomeLogo src={logo} alt="logo BookEasily" />
                </Link>

                <MobileSearch className='rounded-5' method='GET' action="/results/q=" role='search'>
                    <input className="form-control justify-content-center rounded-5" type="search" name='text' id='text' placeholder="🔍 Chercher..." aria-label="Search" />
                </MobileSearch>

                <div className="d-flex justify-content-center">
                    <SearchForm method='GET' action="/results/q=" className='mx-2' role='search'>
                        <input className="form-control justify-content-center rounded-5" type="search" name='text' id='text' placeholder="🔍 Chercher..." aria-label="Search" />
                    </SearchForm>
                    <div className="btn-group bg-white justify-content-start border rounded-5">
                        <button type="button" className="btn dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                            { user ? <StyledLink className='text-capitalize' $theme = {theme}><UserProfil src={ user.photo_url ? `${user.photo_url}` : img } alt={ `${user.last_name}` } /></StyledLink> : <StyledLink $theme = {theme} className='text-capitalize'><i className="bi bi-person-circle text-warning"></i> <span>Compte</span></StyledLink> }
                        </button>
                        <div>
                        { user ? (
                            <ul className="dropdown-menu">
                            {user.email_verified === 1 ? 
                            (
                                <Fragment>
                                    <li className="dropdown-item">
                                        <NavLink to="/dashboard/user" $theme = {theme}>Profil</NavLink>
                                    </li>
                                    <li className="dropdown-item">
                                        <NavLink to="/dashboard" $theme = {theme}>Statistiques</NavLink>
                                    </li>
                                    <li className="dropdown-item">
                                        <NavLink to="/dashboard/categories/hotels/new_ads" $theme = {theme}>Hôtels +</NavLink>
                                    </li>
                                    <li className="dropdown-item">
                                        <NavLink to="/dashboard/categories/meubles/new_ads" $theme = {theme}>Meublés +</NavLink>
                                    </li>
                                    <li className="dropdown-item">
                                        <NavLink to="/dashboard/categories/non_meubles/new_ads" >Non Meublés +</NavLink>
                                    </li>
                                    <hr />
                                </Fragment>)
                                : null}
                                <li className="dropdown-item">
                                    <Form method='POST' onSubmit={handleLogout}>
                                        <LogoutButton type='submit' theme = {theme}>Deconnexion</LogoutButton>
                                    </Form>
                                </li> 
                            </ul> ) :  (
                                    
                            <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                    <NavLink to="/sign-in" $theme = {theme}>Se connecter</NavLink>
                                </li>
                                <li className="dropdown-item">
                                    <NavLink to="/sign-up" $theme = {theme}>S'inscrire</NavLink>
                                </li>
                            </ul>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        </HeaderContainer>
      )
}

export default Admin