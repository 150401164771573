import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useTheme } from "../../../hooks"
import { StyledTitle, TownSection } from "../../../styles/atoms"
import { settingsLinear321 } from "../../../tools"
import CardTowns from '../cards/cardTowns'

function Towns ({villes}) {
    const { theme } = useTheme()

    return (
        <TownSection>
            <div className="col-12">
                <StyledTitle theme={theme}>Destinations</StyledTitle>
                <p className="visually-hidden">Allez à la découverte des bons coins dans chaque ville du Cameroun. Vous pourriez choisir la ville qui vous intéresse et BookEasily vous proposera plusieurs logements disponibles dans celle-ci !</p>
                <div className="col slider-container">
                    <Slider {...settingsLinear321}>
                        {villes.map(({id, town, cover, n_ads, description}) => 
                            <CardTowns key={id} id={id} town={town} cover={cover} count={n_ads} description={description} />
                        ) 
                        }
                    </Slider>
                </div>
            </div>
        </TownSection>
    )
}

export default Towns