import { CatLink } from "../../../styles/atoms"

function CardCategories ({id, name, image, description, count}) {

    return (   
        <CatLink to={`/properties/categories/${name}/${id}`} className="col" key={`${id}-${name}`}>
                <div className="card card-cover overflow-hidden text-bg-dark rounded-4 shadow-md" style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${image})`, backgroundPosition: 'center center'}}>
                    <div className="d-flex flex-column p-5 pb-3 text-shadow-1">
                        <h2 className="py-3 mt-5 mb-3 lead-5 rounded-5 fw-bold">{name}</h2>
                        <ul className="d-flex list-unstyled mt-auto">
                            <li className="me-auto">
                                <p className="card-text lead-1 visually-hidden">{description}</p>
                                { count > 0 ? 
                                    <p className="card-text lead-1">Cliquez pour voir plus de {count} annonces</p>
                                :
                                    <p className="card-text lead-1 disabled"> Bientot disponible</p>
                                }
                                
                            </li>
                        </ul>
                    </div>
                </div>
            </CatLink>        
    )
}

export default CardCategories