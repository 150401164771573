import { Fragment } from "react"
import { Link } from "react-router-dom"
import { useFetch22, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import Admin from "../../../composants/dashboard/header"
import { AdminSection, HeroAdminSection, HeroTitleAdmin, MainContainer, StyledTitle2 } from "../../../styles/atoms"
import Column from "../../../composants/dashboard/column"
import Loading from "../../../composants/public/widgets/loading"
import Stats from "../../../composants/dashboard/stats"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"
import ViewsHotels from "../../../composants/dashboard/viewsHotels"

function ShowHotels() {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null

    document.title = `BookEasily | Annonces - Hotels`
  
    const { theme } = useTheme()

    const { isLoading22, data21, data22, error22 } = useFetch22(
        `https://api.book-easily.com/api/admin/categories/1/ads`,
        `https://api.book-easily.com/api/${ user.role_id === 3 ? `admin` : `user` }/count_items/bookings`,
        token
    ) 
    const hotels = data21
    const countBookings = data22?.data?.bookings + data22?.data?.cancelled

    if (error22) {
        return (
          <Error />
        )
      }

    return user ? (
        <Fragment>
        {/* Hearder Section  */}
          <Admin />
        
          <MainContainer theme={theme}>
            <HeroAdminSection theme={theme}>
              <HeroTitleAdmin theme={theme}>Bienvenue { user ? user.first_name :  null } ! </HeroTitleAdmin>
              <div className="row">
                <StyledTitle2 theme={theme}>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/dashboard"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                      <li className="breadcrumb-item text-warning" aria-current="page">Hotels </li>
                    </ol>
                  </nav>
                </StyledTitle2>
              </div>
            </HeroAdminSection>

            
            <AdminSection>
              <div className="col-md-4 col-lg-3">
                <Column theme={theme} />
              </div>
              
              <div className="col-md-8 col-lg-9"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
              { isLoading22 ? (
              <Loading />
              ) : ( 
                <Fragment>
                { user.role_id === 3 ? 
                  <Fragment>
                    <Stats countBookings={countBookings} />
                    <ViewsHotels group="Hotels" datas={hotels} />
                  </Fragment> 
                : null}
                </Fragment> 
                )}
              </div> 
            </AdminSection>
          </MainContainer>
        <Footer />
        <ScrollButton />
        <MobileBottomHeader/>
      </Fragment>
      ) : (
      <Fragment>
        <Error/>
    </Fragment>
    )
}

export default ShowHotels