import { useTheme } from "../../../hooks"
import { CatLink, SponsorSection } from "../../../styles/atoms"
import I1 from "../../../assets/22.jpg"
import I2 from "../../../assets/25.jpg"
import I6 from "../../../assets/33.jpg"

function Deals () {
    const { theme } = useTheme()
    let images = [
        {
            id: '1',
            title: "Réservation gratuite", 
            img: I1,
            link: `/results/q=?free_booking=1`
        }, 
        {
            id: '2',
            title: "Accéssible aux handicapés", 
            img: I2,
            link: `/results/q=?with_disability=1`
        }, 
        {
            id: '3',
            title: "Découvrez nos Bons Plans", 
            img: I6,
            link: `/best_deals`
        }
    ]

    return (
        <SponsorSection theme={theme}>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-stretch gy-3 pb-2">
                { images.map(({id, title, img, link}) => (
                    <CatLink to={link} className="col" key={id}>
                        <div className="card h-100 text-bg-dark" style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${img})`, backgroundPosition: 'center center', backgroundSize: 'cover'}}>
                            <div className="d-flex flex-column h-100 py-5 px-4 pb-3 text-shadow-1">
                                <h3 className="py-3 mt-5 mb-3 lead-5 fw-bold">{title} </h3>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        Cliquez pour voir les annonces <i className="bi bi-arrow-right-circle"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </CatLink>
                ) ) }
            </div>
        </SponsorSection>
    )
}

export default Deals