import { useEffect, useState } from "react"
import styled from 'styled-components'
import Error from "../widgets/error"
import Loading from "../widgets/loading"
import { AnnonceSection, StyledTitle, TitlePlusSection } from "../../../styles/atoms"
import { useFetch44, useTheme } from "../../../hooks"

const GridSearchHome = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;    
    align-items: center;
    padding: 1.5rem;

    @media (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 540px) {
        grid-template-columns: 1fr;
        gap: 0.2rem;
    }
`

function Filter ({countAds}) {
  const { theme } = useTheme()
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const x_xsrf_token = localStorage.getItem("cookies")
  const [price_max, setPriceMax] = useState()
  const [price_min, setPriceMin] = useState()
  const [start_date, setStartDate] = useState()
  const [end_date, setEndDate] = useState()
  const [town, setTown] = useState("")
  const [streets, setStreets] = useState("")
  const [categorie, setCategorie] = useState("")
  const [types, setTypes] = useState("")
  const [nbPersons, setNbPersons] = useState()

  const { isLoading44, data41, data42, data43, data44, error44 } = useFetch44(
    `https://api.book-easily.com/api/ads/price/get_max_price`,
    `https://api.book-easily.com/api/towns`,
    `https://api.book-easily.com/api/categories`,
    `https://api.book-easily.com/api/ads/price/get_min_price`,
    token
  )
  let max = data41?.data
  const towns = data42?.data
  const categories = data43?.data
  const min = data44?.data
  max = Math.round(max/30)

  useEffect(() => { setPriceMax(max) }, [max])
  useEffect(() => { setPriceMin(min) }, [min])

  const step = Math.round((max-min)/(countAds))

  
  async function handleStreets (e, token, x_xsrf_token){
    e.preventDefault()
    await fetch(`https://api.book-easily.com/api/towns/${e.target.value}/streets`, {
        method: "GET",
        mode: "cors",
        cache: "default",
        credentials: "include",
        
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        }, 
      })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 1) {
            setStreets(data?.data)
            setTown(e.target.value)
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  
  async function handleTypes (e, token, x_xsrf_token){
    e.preventDefault()
    await fetch(`https://api.book-easily.com/api/categories/${e.target.value}/types`, {
        method: "GET",
        mode: "cors",
        cache: "default",
        credentials: "include",
        
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'X-XSRF-TOKEN': `${x_xsrf_token}`
        }, 
      })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 1) {
            setTypes(data?.data)
            setCategorie(e.target.value)
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
}

  if (error44) {
    return (
        <Error />
    )
  }

    return isLoading44 ? (
        <Loading />
        ) : (
            <AnnonceSection id="filter">
                <TitlePlusSection>
                    <StyledTitle theme={theme}>
                        Trouvez un logement <i className="bi bi-search"></i>
                    </StyledTitle>
                </TitlePlusSection>
                <form action="/results/q=" className="mt-2 my__form rounded-4" method="GET">
                <GridSearchHome>
                    <div className="col-12 my-2">
                        <label htmlFor="town" className="form-label">Villes</label>
                        <select className="form-select rounded-5" id="town" name="town" onChange={(e) => handleStreets(e, token, x_xsrf_token) }>
                            <option value="">Ville...</option>
                            {towns.map((town) => (
                                <option key={ `${town.town}-${town.id}` } value={town.id}>{town.town}</option>
                            ))}
                        </select>
                    </div>

                    { town !== '' &&
                        <div className="col-12 my-2">
                            <label htmlFor="street" className="form-label">Quartier</label>
                            <select className="form-select rounded-5" id="street" name="street">
                                <option value=''>Quartier...</option>
                                {streets.map((street) => (
                                    <option key={ `${street.name}-${street.id}` } value={street.id}>{street.name}</option>
                                ))}
                            </select>
                        </div>
                    }

                    <div className="col-12 my-2">
                        <label htmlFor="category" className="form-label">Categories</label>
                        <select className="form-select rounded-5" id="category" name="category" onChange={(e) => handleTypes(e, token, x_xsrf_token) }>
                            <option value="">Categorie...</option>
                            {categories.map((categorie) => (
                                <option key={ `${categorie.name}-${categorie.id}` } value={categorie.id}>{categorie.name}</option>
                            ))}
                        </select>
                    </div>

                    { categorie !== '' &&
                        <div className="col-12 my-2">
                            <label htmlFor="street" className="form-label">Type de logement</label>
                            <select className="form-select rounded-5" id="type" name="type">
                                <option value=''>Type de logement...</option>
                                {types.map((type) => (
                                    <option key={ `${type.name}-${type.id}` } value={type.id}>{type.name}</option>
                                ))}
                            </select>
                        </div>
                    }

                    <div className="col my-2">
                        <label htmlFor="price_min" className="form-label fw-bold text-muted">Budget minimum : <span className="text-warning">{ price_min } XAF</span></label>
                        <input type="range" className="form-range" value={price_min} onChange={(e) => setPriceMin(e.target.value) } min={min} max={price_max} name="price_min"  step={step} id="price_min" />
                    </div>
                    <div className="col my-2">
                        <label htmlFor="price_max" className="form-label fw-bold text-muted">Budget maximum : <span className="text-warning">{ price_max } XAF</span></label>
                        <input type="range" className="form-range" value={price_max} onChange={(e) => setPriceMax(e.target.value) } min={price_min} name="price_max" max={max} step={step} id="price_max" />
                    </div>
                    <div className="col-12 my-2">
                        <label htmlFor="start_date" className="form-label">Date d'arrivée</label>
                        <div className="input-group">
                            <div className="input-group-text">Du</div>
                            <input type="datetime-local" className="form-control" id="start_date" max={end_date} value={start_date} name="start_date" onChange={(e) => setStartDate(e.target.value) }/>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <label htmlFor="end_date" className="form-label">Date de départ</label>
                        <div className="input-group">
                            <div className="input-group-text">Au</div>
                            <input type="datetime-local" className="form-control" id="end_date" min={start_date} name="end_date" value={end_date} onChange={(e) => setEndDate(e.target.value) }/>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <label htmlFor="number_persons" className="form-label">Nombre de personnes</label>
                        <div className="input-group">
                            <input type="number" className="form-control rounded-5"  id="number_persons" placeholder="Nombre de personnes..." name="number_persons" value={nbPersons} onChange={(e) => setNbPersons(e.target.value)}/>
                        </div>
                    </div>
                </GridSearchHome>
                
                <div className="col-10 offset-1 mt-2 mb-4">
                    <button type="submit" className="btn w-100 btn-warning me-2 fw-bold rounded-5"><i className="bi bi-search"></i> Rechercher</button>
                </div>
            </form>
        </AnnonceSection>
    )
}

export default Filter