import React, {Fragment, useState} from 'react'
import { Button } from "./styles/stylesHelpButton"

const HelpButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 100){
	setVisible(true)
	}
	else if (scrolled <= 100){
	setVisible(false)
	}
};

window.addEventListener('scroll', toggleVisible);

return (
	<Fragment>
		<Button href="https://wa.me/237695931631" className='rounded-5 shadow bg-warning' style={{display: visible ? 'block' : 'none', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
			Aide ?
		</Button>
	</Fragment>
);
}

export default HelpButton;
