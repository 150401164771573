import { Link } from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ImgCardUpsell, SpanPriceUpsell } from "../../../styles/atoms"

function CardUpsell ({id, town, title, price, cover, description}) {
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    customPaging: function(i) {
      return (
        <div className="row">
          <Link className="col">
            <img src={`${cover[i]}`} className="rounded-1" loading="lazy"  alt="BookEasily" width="20px" height="20px" />
          </Link>
        </div>
      )
    },
  }
    return (
      <div className="col rounded card my__card m-1" key={`${id}-${title}`}>
        <Slider {...settings}>
          {cover.map(image => (
            <ImgCardUpsell src={image} loading="lazy" key={image.id} className="img-fluid card-img" alt={ `${description}` } />
          ))} 
        </Slider>
        <SpanPriceUpsell className="fw-bold">{price}</SpanPriceUpsell>
        <div className="card-body mt-4">
          <h4 className="fw-bold">{town} - {title}</h4>
          <p>{description}</p>
        </div>

        <div className="card-footer row g-2">
          <div className="col-12 col-md-6">
            <Link to="https://wa.me/237695931631" className="btn btn-success px-1 fw-bold w-100 rounded-5" target='_blank'><i className="bi bi-whatsapp" ></i> (+237)695931631</Link>
          </div>
          <div className="col-12 col-md-6">
            <Link to="mailto:contact@book-easily.com?subject=Je%20suis%20interréssé(e)%20par%20un%20Bon%20Plan%20!" className="btn btn-outline-success fw-bold w-100 rounded-5" target='_blank'><i className="bi bi-envelope"></i> Mail</Link>
          </div>
        </div>
      </div>
    )
}

export default CardUpsell