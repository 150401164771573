import React, { Fragment }  from "react"
import { useFetch33, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import Header from "../../../composants/public/sections/header"
import { MainContainer } from "../../../styles/atoms"
import Hero from "../../../composants/public/sections/hero"
import Loading from "../../../composants/public/widgets/loading"
import Badge from "../../../composants/public/sections/badge"
import Towns from "../../../composants/public/sections/towns"
import Explore from "../../../composants/public/sections/explore"
import CallToAction from "../../../composants/public/sections/callToAction"
import Deals from "../../../composants/public/sections/deals"
import Sponsors from "../../../composants/public/sections/sponsors"
import Features from "../../../composants/public/sections/features"
import Search from "../../../composants/public/sections/search"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"

function Home() {
  document.title = `BookEasily | Trouvez votre prochain logement`

  const { theme } = useTheme()
  // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  
  const { isLoading33, data31, data32, data33, error33 } = useFetch33(
    `https://api.book-easily.com/api/ads?per_page=10`,
    `https://api.book-easily.com/api/towns`,
    `https://api.book-easily.com/api/categories`,
    token
  )   
  const annonces = data31?.data
  const villes = data32?.data
  const categories = data33?.data
  
  if (error33) {
    return (
      <Error />
    )
  }

  return (
      <Fragment>
        <Header/>
        
        <MainContainer theme = {theme}>
          {/* { user.email_verified === 0 && 
              (<div className="alert alert-danger text-center" role="alert">
                  Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
              </div>)
          } */}
          <Hero/>

          { isLoading33 ? (
            <Loading/>

            ) : (
            <Fragment>
              {/* Services Section */}
              <Search />
              <Features categories={categories}/>

              {/* Sponsoring Section */}
              <Sponsors annonces={annonces} />
    
              <Badge />

              {/* Towns Section */}
              <Towns villes={villes} />
    

              {/* Explore Section */}
              <Explore />

              {/* CTA Section */}
              <CallToAction />

              {/* Deals Section */}
              <Deals />
            </Fragment>
          ) }
        </MainContainer>

        {/* Footer Section */}
        <Footer />
        <ScrollButton />
        <MobileBottomHeader/>
      </Fragment>
    )
}

export default Home